import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { toast } from "react-toastify";
import SuccessPop from "../Common/modal/SuccessPop";
import API from "../../Api/Api";
import LoginPageBg from "../../Assets/Images/Login/loginBg.png";

const VerifyOtpMail = () => {
  const navigate = useNavigate();
  const path = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const AuthToken = localStorage.getItem("AuthTokenClient");
  const numberOfDigits = 4;
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [counter, setCounter] = useState(30);
  const email = JSON.parse(queryParams.get("email"));
  const [showPopup, setShowPopUp] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");
  const otpBoxReference = useRef([]);

  useEffect(() => {
    const timeout = setTimeout(() => { }, 30000);

    const interval = setInterval(() => {
      if (counter) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [counter]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  const handleVerifyOtp = (e) => {
    setIsSubmit(true);
    e.preventDefault();
    if (path.path == 2) {
      try {
        API.Auth.VerifyViaMail({
          data: {
            email: email,
            otp: otp.join(""),
            role: "Client",
          },
          tokenRequired: AuthToken,
        }).then((response) => {
          if (response?.data?.status === 1) {
            if (
              !response?.data.userdata?.is_verified ||
              !response?.data.userdata?.is_verifiedp
            ) {
              if (!response?.data.userdata?.is_verifiedp) {
                navigate(
                  `../${AdminRoute?.Auth?.VerifyOtp
                  }/1?mobile_no=${JSON.stringify(
                    response?.data.userdata?.email
                  )}`
                );
              }
              // if (!response?.data.userdata?.is_verified) {
              // navigate(
              //   `../${
              //     AdminRoute?.Auth?.VerifyOtp
              //   }/1?mobile_no=${JSON.stringify(
              //     response?.data.userdata?.phone
              //   )}`
              // );
              // }
            } else if (
              response?.data.userdata?.is_verifiedp &&
              response?.data.userdata?.is_verified
            ) {
              localStorage.setItem("AuthTokenClient", response?.data?.token);
              localStorage.setItem(
                "ClientUserData",
                JSON.stringify(response?.data)
              );
              setShowPopUp(true);
              setTimeout(() => {
                setShowPopUp(false);
                navigate(`../${AdminRoute?.Common?.Group}`);
              }, 2000);
            } else {
              setError(response?.data?.message);
              // toast.error(`${response.data.message}`);
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
      setIsSubmit(false);
    }
    if (path.path == 1) {
      try {
        API.Auth.VerifyViaMail({
          data: {
            email: email,
            otp: otp.join(""),
            role: "Client",
          },
          tokenRequired: AuthToken,
        }).then((response) => {
          if (response?.data?.status === 1) {
            localStorage.setItem("AuthTokenClient", response?.data?.token);
            localStorage.setItem(
              "ClientUserData",
              JSON.stringify(response?.data)
            );
            setShowPopUp(true);
            if (
              !response?.data.userdata?.is_verified ||
              !response?.data.userdata?.is_verifiedp
            ) {
              if (!response?.data.userdata?.is_verifiedp) {
                navigate(
                  `../${AdminRoute?.Auth?.VerifyOtp
                  }/1?mobile_no=${JSON.stringify(
                    response?.data.userdata?.email
                  )}`
                );
              }
              if (!response?.data.userdata?.is_verified) {
                navigate(
                  `../${AdminRoute?.Auth?.VerifyOtp
                  }/1?mobile_no=${JSON.stringify(
                    response?.data.userdata?.phone
                  )}`
                );
              }
            } else if (
              response?.data.userdata?.is_verifiedp &&
              response?.data.userdata?.is_verified
            ) {
              if (response?.data.userdata.step == 3) {
                navigate(`../${AdminRoute?.Common?.Group}`);
              } else if (response?.data.userdata.step == 4) {
                navigate(`../${AdminRoute?.Common?.Plan}`);
              } else if (response?.data.userdata.step == 5) {
                navigate(`../${AdminRoute?.Common?.Therapist}`);
              } else if (response?.data?.userdata?.step == 6) {
                setTimeout(() => {
                  setShowPopUp(false);
                  navigate(`../${AdminRoute?.Common?.Dashboard}`);
                }, 2000);
              }
            }
            // if (response.data.userdata.is_verifiedp && response.data.userdata.is_verified) {
            //   localStorage.setItem("AuthTokenClient", response?.data?.token);
            //   localStorage.setItem(
            //     "ClientUserData",
            //     JSON.stringify(response?.data)
            //   );
            //   setShowPopUp(true)
            //   setTimeout(() => {
            //     setShowPopUp(false)
            //     navigate(`../${AdminRoute?.Common?.Dashboard}`);
            //   }, 2000)
            // } else if (!response.data.userdata.is_verifiedp) {
            //   navigate(`../${AdminRoute?.Auth?.VerifyOtp}/1?mobile_no=${JSON.stringify(response.data.userdata?.phone)}`);
            //   toast.error("Verify Email First")
            //   handleResendOtpPhone(response.data.userdata?.phone)
            // }
          } else {
            // toast.error(`${response.data.message}`);
            setError(response?.data?.message);
          }
        });
      } catch (error) {
        console.log(error);
      }
      setIsSubmit(false);
    }
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    try {
      API.Auth.LoginAPIEmail({
        data: {
          email: email,
          role: "Client",
          resend: true,
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.message);
          setError("");
        } else {
          setError(response?.data?.message);
          // toast.error(response?.data?.message);
        }
        setOtp(["", "", "", ""]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendOtpPhone = (mobile_no) => {
    try {
      API.Auth.LoginAPIMobile({
        data: {
          phone: mobile_no,
          role: "Client",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          setError("");
          // toast.success(response?.data?.message);
        } else {
          // toast.error(response?.data?.message);
          setError(response?.data?.message);
        }
        setOtp(["", "", "", ""]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {showPopup ? (
        <SuccessPop
          text={
            path.path === 1 ? "Login Successful" : "Registration Successful"
          }
        />
      ) : (
        <>
          {/* <div className="Otp">
            <div className="container login-page">
              <div className="row main-container">
                <div className="col-md-6 col-sm-12 left-text">
                  <div className="logo-div">
                    <NavLink to="/">
                      <img src="/images/logo.png" className="logo" alt="logo" />{" "}
                    </NavLink>
                  </div>
                  <h1 className="heading">Reclaim control over your</h1>
                  <p className="subheading">Mental Health journey</p>
                  <p>
                    through our online therapy programs, guided by our
                    compassionate team of psychologists.
                  </p>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="otp-div">
                    <h2 className="heading">Verify via E-mail OTP</h2>

                    <p>
                      <span className="text-danger fw-bold">*</span> Enter 4 digit
                      OTP sent on the registered <b> &quot;{email}&quot; </b>
                      Email ID
                    </p>
                    <div
                      style={{ justifyContent: "space-between", display: "flex" }}
                    >
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          value={digit}
                          maxLength={1}
                          onChange={(e) => handleChange(e.target.value, index)}
                          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                          ref={(reference) =>
                            (otpBoxReference.current[index] = reference)
                          }
                          className="form-control custom-input-text-primary otp-input mb-0"
                          autoComplete="off"
                        />
                      ))}
                    </div>
                    <button
                      className="button_common"
                      disabled={isSubmit}
                      onClick={(e) => handleVerifyOtp(e)}
                    >
                      Verify and Proceed
                    </button>
                    {error && <p className="text-danger">{error}</p>}
                    <p
                      className="orange-small-link text-center text-decoration-underline cursor_pointer resend-otp"
                      onClick={(e) => handleResendOtp(e)}
                    >
                      Resend OTP
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="w-full bg-gradient-to-b from-[#DDE4ED] to-[#FAE2DF] min-h-screen flex items-center justify-center py-[1%] px-[2%] scrollbar-hide">
            <div className="bg-white bg-opacity-75 rounded-[50px] shadow-lg w-full flex py-[3%]">
              <div className="grid grid-cols-2 md:grid-cols-5 gap-4 pl-0 md:pl-[6%]">
                <div className="bg-white rounded-[50px] shadow-lg px-12 col-span-2 py-6">
                  <div className="flex justify-center py-4">
                    <img src="/images/logo.png" className="h-auto w-full max-w-[200px]" />
                  </div>
                  <div className="flex justify-center pb-4">
                    <span className="font-extrabold font-poppins-bold text-2xl">BEGIN YOUR WELLNESS JOURNEY</span>
                  </div>
                  <div className="flex justify-center pb-4">
                    <span className="font-bold font-poppins-regular text-xl">Verify via E-mail OTP</span>
                  </div>
                  <div className="w-full">
                    <span className="text-danger fw-bold">*</span>  OTP sent on the registered <b> &quot;{email}&quot; </b>
                    Email ID
                   
                    <div className="mt-2"
                      style={{ justifyContent: "space-between", display: "flex" }}
                    >
                     {otp.map((digit, index) => (
                        <input
                          key={index}
                          value={digit}
                          maxLength={1}
                          onChange={(e) => handleChange(e.target.value, index)}
                          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                          ref={(reference) =>
                            (otpBoxReference.current[index] = reference)
                          }
                          className="form-control custom-input-text-primary otp-input mb-0 mr-1 outline-none"
                          autoComplete="off"
                        />
                      ))}
                    </div>
                    <div className="flex justify-center item-center">
                      <button
                        className="w-fit mt-3 mb-3 font-bold py-2 px-4 rounded-[30px] border-0 bg-[#F5BD41] text-black"
                        disabled={isSubmit}
                        onClick={(e) => handleVerifyOtp(e)}
                      >
                        Verify and Proceed
                      </button>
                    </div>

                    {error && <p className="text-danger">{error}</p>}
                    <p
                      className="orange-small-link text-center text-decoration-underline cursor_pointer resend-otp"
                      onClick={(e) => handleResendOtp(e)}
                    >
                      Resend OTP
                    </p>

                  </div>
                </div>
                <div className="hidden md:flex flex-col justify-center col-span-3 ml-[12%]">
                  <h2 className="text-4xl text-gray-900 font-lusitana font-normal leading-snug mr-[16%] py-[4%]">Experience 24/7 companionship for your mental well-being grow with personalized support.</h2>
                  {/* <h3 className="text-md text-gray-700 py-[3%] font-medium ">Sign in or create your account now to start your journey to better mental health</h3> */}
                  <img src={LoginPageBg} alt="LoginPageBanner" className="h-auto w-full max-w-[400px]" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VerifyOtpMail;
