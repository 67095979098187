import React from 'react';
import defaultImage from "../../Assets/Images/Groups/defaultGroupImage.png";
import baseApi from "../../Api/config";


const GroupCards = ({ data, onClick, isSelected }) => {

    console.log('groupCard', data);
    const timestamp = data.createdAt;
    const dateObj = new Date(timestamp);
    const date = dateObj.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
    const time = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
        <div className={`flex flex-row items-center justify-between w-full max-w-2xl rounded-3xl overflow-hidden ${isSelected ? 'bg-[#FAF5DA8F]' : 'bg-[#F5F6FB]'} mb-4 cursor-pointer`} onClick={onClick}>
            <div className="flex items-center p-3 w-full h-full">
                <div className="w-12 h-12 rounded-full overflow-hidden">
                    <img
                        src={data.logo ? `${baseApi.baseurl}images/${data?.logo}` : defaultImage}
                        alt="Profile"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="flex flex-col ml-4 flex-grow">
                    <div className="flex justify-between items-center w-full">
                        <h3 className="text-md font-bold text-gray-900 truncate">{data.name}</h3>
                        <span className="text-xs text-[#475569] font-semibold">{date}</span>
                    </div>
                    <div className="flex justify-between items-center w-full">
                        <div className='flex flex-grow justify-between'>
                            <p className="text-gray-700 text-xs mt-2 truncate">{data.description.length > 20 ? data.description.slice(0, 20) + '...' : data.description}</p>
                            <span className="text-xs text-[#475569] mt-2 font-semibold">{time}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupCards