import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import useRazorpay from "react-razorpay";
import PaymentSucces from "../Common/modal/PaymentSucessFull";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import PlanCard from "../PlanCard";
import PricePageBG from "../../Assets/Images/PricepageBG.jpg";

function Plan() {
  const [PlanList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [showPopup, setShowPopUp] = useState(false);
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const PlanUpgrade = queryParams.get("plan");

  const Logoutfun = () => {
    localStorage.removeItem("EmailId");
    localStorage.removeItem("AuthTokenClient");
    localStorage.removeItem("ClientUserData");
    navigate(`../${AdminRoute?.Auth?.Login}`);
  };

  useEffect(() => {
    getPlanList();
  }, []);

  //api call for plan list
  const getPlanList = () => {
    setLoading(true);
    try {
      API.Auth.PlanPlanListAPI({}).then((response) => {
        if (response?.data?.status === 1) {
          setPlanList(response?.data?.data);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handlePlanCheckPayment = (plan_id) => {
    API.Payment.ChackPlanAllow({
      data: {
        name: UserData?.userdata?.name,
        created_by: UserData?.userdata?._id,
        amount: plan_id?.amount,
        plan_id: plan_id?._id,
      },
    })
      .then((response) => {
        if (response?.data?.status == 1) {
          handlePlan(plan_id);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // select plan api call
  const handlePlan = (plan_id) => {
    if (plan_id.name === "Pay Per Feature") {
      handlePlanSave(plan_id);
    } else {
      // API.Auth.SelectThemeById({
      //   data: {
      //     plan_id: plan_id?._id,
      //   },
      //   UserId: UserData?.userdata?._id,
      // })
      //   .then((response) => {
      //     if (response.data.status) {
      //       // navigate(`../${AdminRoute?.Common?.Theam}`);
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // get rezorpay orderid id
      API.Payment.RezorPayGetId({
        data: {
          name: UserData?.userdata?.name,
          created_by: UserData?.userdata?._id,
          amount: plan_id?.amount,
          plan_id: plan_id?._id,
        },
      })
        .then((response) => {
          if (response?.data?.message === "successfully") {
            RezorPaySdk(response?.data?.data, plan_id, response?.data?.key_id);
            // showRazorpay(response.data.data.id, plan_id.amount, plan_id,)
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const RezorPaySdk = (orderID, plan_id, appKey) => {
    // let Amount = Math.round(amount * 1000)
    const options = {
      // key: "rzp_test_nYt1RNLg2H6FzV", // Enter the Key ID generated from the Dashboard
      key: appKey,
      amount: orderID?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: orderID?.currency,
      name: "RYT",
      description: orderID?.notes?.description
        ? orderID?.notes?.description
        : "Test",
      image: "https://example.com/your_logo",
      order_id: orderID.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        console.log("success");
        handleRezorpayResponce(
          response,
          orderID.id,
          orderID.amount,
          "finish",
          plan_id
        );
        handlePlanSave(plan_id);
      },
      prefill: {
        name: UserData?.userdata?.name,
        email: UserData?.userdata?.email,
        contact: UserData?.userdata?.phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      handleRezorpayResponce(
        response,
        orderID,
        orderID?.amount,
        "decline",
        plan_id
      );
    });
    rzp1.open();
  };

  const handleRezorpayResponce = (
    response,
    orderID,
    amount,
    status,
    plan_id
  ) => {
    API.Payment.Paymentupdate({
      data: {
        order_id: orderID,
        name: UserData?.userdata?.name,
        created_by: UserData?.userdata?._id,
        amount: Math.floor(amount / 100),
        responce_obj: response,
        order_status: status,
        paymentfeatures: plan_id?.name,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          setShowPopUp(true);
          setSucesspayment(plan_id);
          if (!PlanUpgrade) {
            handleStep(plan_id);
          }
          if (plan_id.name === "BASIC Plan") {
            setTimeout(() => {
              setShowPopUp(false);
              if (PlanUpgrade) {
                navigate(`../${AdminRoute?.Common.Dashboard}`);
              } else {
                navigate(`../${AdminRoute?.Common?.Theam}?plan=2`);
              }
            }, 1000);
          } else if (plan_id.name === "PRO Plan") {
            setTimeout(() => {
              setShowPopUp(false);
              if (PlanUpgrade) {
                navigate(`../${AdminRoute?.Common.Dashboard}`);
              } else {
                navigate(`../${AdminRoute?.Common?.Theam}?plan=3`);
              }
            }, 1000);
          } else {
            setTimeout(() => {
              setShowPopUp(false);
              if (PlanUpgrade) {
                navigate(`../${AdminRoute?.Common.Dashboard}`);
              } else {
                navigate(`../${AdminRoute?.Common?.Theam}?plan=2`);
              }
            }, 1000);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setSucesspayment = (plan_id) => {
    API.Auth.SelectThemeById({
      data: {
        plan_id: plan_id?._id,
      },
      UserId: UserData?.userdata?._id,
    })
      .then((response) => {
        if (response.data.status) {
          // navigate(`../${AdminRoute?.Common?.Theam}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePlanSave = (plan_id) => {
    API.Payment.Adduserplan({
      data: {
        planid: plan_id._id,
        isupdate: PlanUpgrade ? true : false,
      },
      UserId: UserData?.userdata?._id,
    })
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.status === 1) {
          if (plan_id?.name === "Pay Per Feature") {
            if (PlanUpgrade) {
              navigate(`../${AdminRoute?.Common?.Theam}?plan=2&feature=true`);
              handleStep(plan_id);
            } else {
              navigate(`../${AdminRoute?.Common?.Theam}?plan=2&feature=true`);
              // navigate(`../${AdminRoute?.Common.Theam}`);

              handleStep(plan_id);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStep = (plan_id) => {
    try {
      API.Auth.UpdateProfileAPI({
        data: {
          step: plan_id?.name === "Pay Per Feature" ? 6 : 5,
        },
        UserId: UserData?.userdata?._id,
      }).then((response) => {
        console.log(response);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //jsx
  return (
    <>
      {showPopup ? (
        <PaymentSucces
          text="Payment Successful"
          desc="congratulations your payment is successfull."
        />
      ) : (
        <>
          {/* <div className="main-container therapist pb-0">
            <div className="text-center inner">
              <div className="pricing position-relative">
                <div className="position-absolute top-0 end-0 logout-icon">
                  <button
                    type="submit"
                    className="w-fit mt-3 mb-3 font-bold py-2 px-4 rounded-[30px] border-0 bg-[#F5BD41] text-black cursor-pointer"
                    onClick={() => Logoutfun()}
                  >
                    Logout <i className="fa fa-sign-out ms-2"></i>
                  </button>
                </div>
                <div className="row">
                  <h2 className="heading text-center">Select a Plan</h2>
                  <p className="text-center mb-4">
                    Help us serve you better by selecting a plan that sits you
                    best.
                  </p>
                  {loading && <p>Loading...</p>}
                  {PlanList &&
                    PlanList?.map((val) => {
                      return (
                        <>
                          {val?.name === "PRO Plan" ? (
                            <div
                              className="col-md-4  col-sm-12 cursor_pointer"
                              key={val._id}
                            >
                              <div
                                className="pricing-card pricing-card-highlight mb-4"
                                onClick={() => handlePlanCheckPayment(val)}
                              >
                                <div className="pricing-card-heading pricing-card-heading-pro p-4">
                                  <h3>{val?.name}</h3>
                                  <h4>Rs. {val?.amount}/-</h4>
                                  <p>Monthly subscription</p>
                                </div>
                                <h5 className="mt-3 text-start features-heading">
                                  Features
                                </h5>
                                <ul className="pricing-card-list">
                                  {val?.feature?.length &&
                                    Object.entries(val?.feature[0]).map(
                                      ([key, value], index) => {
                                        return (
                                          <li
                                            key={index}
                                            className={`pricing-card-list-item ${value ? "icon-yes" : "icon-no"
                                              }`}
                                          >
                                            {key}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </div>
                            </div>
                          ) : val?.name === "BASIC Plan" ? (
                            <div
                              className="col-md-4  col-sm-12 cursor_pointer"
                              key={val?._id}
                            >
                              <div
                                className="pricing-card pricing-card-highlight  mb-4"
                                onClick={() => handlePlanCheckPayment(val)}
                              >
                                <div className="pricing-card-heading pricing-card-heading-lite p-4">
                                  <h3>{val?.name}</h3>
                                  <h4>Rs. {val?.amount}/-</h4>
                                  <p className="text-white">
                                    Monthly subscription:
                                  </p>
                                </div>
                                <h5 className="mt-3 text-start features-heading">
                                  Features
                                </h5>
                                <ul className="pricing-card-list">
                                  {val?.feature?.length &&
                                    Object?.entries(val?.feature[0]).map(
                                      ([key, value], index) => {
                                        return (
                                          <li
                                            key={index}
                                            className={`pricing-card-list-item ${value ? "icon-yes" : "icon-no"
                                              }`}
                                          >
                                            {key}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="col-md-4  col-sm-12 cursor_pointer"
                              key={val._id}
                            >
                              <div
                                className="pricing-card pricing-card-highlight mb-4"
                                onClick={() => handlePlanCheckPayment(val)}
                              >
                                <div className="pricing-card-heading pricing-card-heading-free p-4">
                                  <h3>{val?.name}</h3>
                                </div>
                                <h5 className="mt-3 text-start features-heading">
                                  Features
                                </h5>
                                <ul className="pricing-card-list">
                                  {val?.feature?.length &&
                                    Object?.entries(val?.feature[0]).map(
                                      ([key, value], index) => {
                                        return (
                                          <li
                                            key={index}
                                            className={`pricing-card-list-item ${value ? "icon-yes" : "icon-no"
                                              }`}
                                          >
                                            {key}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div> */}
          <div
            className="w-full mx-auto px-4 sm:px-6 lg:px-8 py-8"
            style={{
              backgroundImage: `url(${PricePageBG})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed',
                          }}
          >

            <div className="text-center mb-4">
              <p className="text-[40px] font-semibold text-[#231D4F]">Pricing That Cares</p>
              <p className="text-lg text-gray-600 mt-2">
                Help us serve you better by selecting a plan that suits you best
              </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {PlanList?.length &&
                PlanList.map((val, i) => {
                  console.log('planValue',val);
                  return (
                    
                    <div key={i} className="flex justify-center items-center mt-2">
                      <PlanCard
                        planName={val?.name}
                        amount={val?.amount}
                        features={val?.feature}
                        onClick={() => handlePlanCheckPayment(val)}
                      />
                    </div>
                  );

                })}
            </div>
          </div>

        </>
      )}
    </>
  );
}
export default Plan;
