// App.js
import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import AiTherapist from "../../Assets/Images/ai-therapist.jpg";
import PlayGround from "../../Assets/Images/playground.jpg";
import Groups from "../../Assets/Images/groups.jpg";
import BookSession from "../../Assets/Images/book-session.jpg";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { checkDate, get15MinutesBefore } from "../Common/Functions";
import { FeatrureList } from "../../App";
import JoinSession from "../JoinSession";
import baseApi from "../../Api/config";
import Mood5 from "../../Assets/Images/moodTracker/1.png";
import Mood4 from "../../Assets/Images/moodTracker/2.png";
import Mood3 from "../../Assets/Images/moodTracker/3.png";
import Mood2 from "../../Assets/Images/moodTracker/4.png";
import Mood1 from "../../Assets/Images/moodTracker/5.png";
// import "../../Assets/css/waves.css"
import AiImage from "../../Assets/Images/dashboard/Girl_Computer.png";
import GroupsImage from "../../Assets/Images/dashboard/groups.png";
import playgroundImage from "../../Assets/Images/dashboard/Playground.png";
import grass from "../../Assets/Images/dashboard/grass3.png";
import part1 from "../../Assets/Images/dashboard/clock.png";
import { IoIosSend } from "react-icons/io";
import { TbBulb } from "react-icons/tb";
import moodTrackerHeading from "../../Assets/Images/dashboard/mood_tacker_heading.png";
import { IoMdSend } from "react-icons/io";
import MovingComponent from 'react-moving-text'
import { toast } from "react-toastify";



function Dashboard() {
  const featureListContext = useContext(FeatrureList);
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [DigitalNotesList, setDigitalNotesList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [CurrentPlan, setCurrentPlan] = useState([]);
  const [moodTraker, setMoodTraker] = useState({
    Joy: "50",
    drive: "50",
    stress: "50",
  });
  const [lifeStyleTraker, setLifeStyleTraker] = useState({
    fodIntake: "50",
    sleep: "50",
    activity: "50",
  });
  const [featurePrice, setFeaturePrice] = useState([]);
  const [currentSession, setcurrentSession] = useState({});
  const [ActivityCount, setActivityCount] = useState(0);
  const [MessagecntCount, setMessagesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [FocussedData, setFocussedData] = useState([]);
  const [joinSessionBtn, setJoinSessionBtn] = useState(false);
  const [therapistData, setTherapishData] = useState({});
  const [showTagSelection, setShowTagSelection] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [dynamicMessage, setDynamicMessage] = useState('');
  const [selectedMood, setSelectedMood] = useState({ happy: null, stress: null, motivation: null });
  const [thumbStyles, setThumbStyles] = useState({
    happy: { left: '0%', backgroundColor: '#D8D8D8' },
    stress: { left: '0%', backgroundColor: '#D8D8D8' },
    motivation: { left: '0%', backgroundColor: '#D8D8D8' }
  });
  const [isDisabled, setIsDisabled] = useState({ happy: false, stress: false, motivation: false });
  const [wellnessTagsList, setWellnessTagsList] = useState([]);



  useEffect(() => {
    getDigitalNoteList();
    MyPlanlfeatures();
    FeaturePriceList();
    getcurrentSession();
    getFocussedData();
    TheripistDeatils();
    getWellnessTags();
  }, []);

  const TheripistDeatils = () => {
    API.Therapist.getTherpistDetails({
      date: moment(new Date()).format("YYYY-MM-DD"),
    })
      .then((response) => {
        if (response.data.status === 1) {
          setTherapishData(response?.data.data_all[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDigitalNoteList = () => {
    setLoadingList(true);
    try {
      API.Auth.DigitalNotesList().then((response) => {
        if (response.status === 200) {
          setDigitalNotesList(response?.data?.data_all);
          setLoadingList(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingList(false);
    }
  };

  const MyPlanlfeatures = () => {
    setLoading(true);
    try {
      API.Payment.MyPlanlfeatures().then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setCurrentPlan(response.data.data);
          featureListContext.setCurrentPlan(response.data.data);
          if (response.data.welnessdata.length) {
            setMoodTraker({
              ...moodTraker,
              drive: response.data.welnessdata[0].drive,
              Joy: response.data.welnessdata[0].joy,
              stress: response.data.welnessdata[0].stress,
            });
            setLifeStyleTraker({
              ...lifeStyleTraker,
              fodIntake: response.data.welnessdata[0].foodIntake,
              sleep: response.data.welnessdata[0].sleep,
              activity: response.data.welnessdata[0].physicalactivity,
            });
          }
        }
      });
    } catch (error) {
      false(false);
      console.log(error);
    }
  };

  const FeaturePriceList = () => {
    try {
      API.Payment.FeaturePriceList().then((response) => {
        if (response.status === 200) {
          setFeaturePrice(response.data.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const handlewellness = (val, value) => {
  //   API.Survey.Wellness({
  //     data: {
  //       joy: val === "Joy" ? value : moodTraker.Joy,
  //       foodIntake: val === "fodIntake" ? value : lifeStyleTraker.fodIntake,
  //       drive: val === "drive" ? value : moodTraker.drive,
  //       sleep: val === "sleep" ? value : lifeStyleTraker.sleep,
  //       stress: val === "stress" ? value : moodTraker.stress,
  //       physicalactivity: val === "activity" ? value : lifeStyleTraker.activity,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.data.status === 1) {
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getcurrentSession = () => {
    try {
      API.Session.currentSessionList().then((response) => {
        if (response.status === 200) {
          setcurrentSession(response.data.data_all[0]);
          setActivityCount(response.data.activitycount);
          setMessagesCount(response.data.messagecntcount);
          // console.log(response.data.data_all[0].date);
          // console.log(
          //   get15MinutesBefore(response.data.data_all[0].date.slice(0, -1))
          // );

          // Provided time
          const providedTime = moment(
            get15MinutesBefore(response?.data?.data_all[0]?.date?.slice(0, -1))
          );

          // Current time
          const currentTime = moment();
          const endTime = moment(response?.data?.data_all[0]?.timeend);
          // Subtract 15 minutes from the current time
          const fifteenMinutesBefore = currentTime
            .clone()
            .subtract(15, "minutes");
          // Check if provided time is within 15 minutes before current time
          if (currentTime.isBetween(providedTime, endTime)) {
            // console.log(
            //   "Provided time is within 15 minutes before the current time."
            // );
            setJoinSessionBtn(true);
          } else {
            // console.log(
            //   "Provided time is NOT within 15 minutes before the current time."
            // );
            setJoinSessionBtn(false);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayment = (featureName, fetaure) => {
    if (featurePrice.length) {
      const Feature = featurePrice.find(
        (feature) => feature.feature_name === featureName
      );
      navigate(
        `../${AdminRoute.Common.MakePayment}/${fetaure.features
        }/?planID=${JSON.stringify(fetaure._id)}&amount=${JSON.stringify(
          btoa(Feature.feature_price)
        )}`
      );
    }
  };

  const getFocussedData = (info) => {
    API.Auth.Support_API({
      support: "focused",
    })
      .then((response) => {
        if (response.data.status === 1) {
          setFocussedData(response?.data?.data_all);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderActionButton = () => {
    API.Session.JoinMeeting({
      data: {
        role: "Cmsemployee",
        meetingId: currentSession?.mettingname,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          navigate(
            `../${AdminRoute.Video.VideoCall}?id=${btoa(
              currentSession.meetingtoken
            )}&name=${currentSession.mettingname}`
          );
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moods = [
    { img: Mood5, value: 5, color: '#D8D8D8' },
    { img: Mood4, value: 4, color: '#D8D8D8' },
    { img: Mood3, value: 3, color: '#D8D8D8' },
    { img: Mood2, value: 2, color: '#D8D8D8' },
    { img: Mood1, value: 1, color: '#D8D8D8' }
  ];

  const stressMoods = [
    { img: Mood5, value: 1, color: '#D8D8D8' },
    { img: Mood4, value: 2, color: '#D8D8D8' },
    { img: Mood3, value: 3, color: '#D8D8D8' },
    { img: Mood2, value: 4, color: '#D8D8D8' },
    { img: Mood1, value: 5, color: '#D8D8D8' }
  ];


  const insertUserWellnessTag = (id, name) => {
    API.Survey.InsertIndividualWellnessTags({
      data: {
        tagId: id,
        tagName: name,
        userId: UserData?.userdata?._id,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          getSelectedWellnessTags();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getSelectedWellnessTags = () => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(); // Get the current date
    const formattedDate = formatDate(date);
    // setTimeZone(clientTimeZone);
    try {
      API.Survey.getIndividualWellnessTags({
        data: {
          clientId: UserData?.userdata?._id,
          clientTimeZone: clientTimeZone,
          clientDate: formattedDate
        },
      })
        .then((response) => {

          if (response.data.status === 1) {
            const checkStatus = response.data.data;

            if (checkStatus.length < 5) {
              setShowTagSelection(true);
            } else {
              setShowTagSelection(false);
            }
            setSelectedTags(checkStatus);

          }
        })
    } catch (error) {
      console.log(error);
    }
  }

  const handlewellness = (type, value) => {
    if (isDisabled[type]) return;

    API.Survey.Wellness({
      data: { type: type, value },
    })
      .then((response) => {
        if (response.data.status === 1) {
          setSelectedMood((prevState) => ({ ...prevState, [type]: value }));
          setIsDisabled((prevState) => ({ ...prevState, [type]: true }));
          updateThumbPosition(type, value);
        }
      })
      .catch((error) => console.log(error));
  };

  const updateThumbPosition = (indicator, value) => {
    const selectedMood = moods.find((mood) => mood.value === value);

    if (selectedMood) {
      const index = moods.indexOf(selectedMood);
      const adjustedIndex = indicator === "stress" ? (moods.length - 1 - index) : index;
      const leftPosition = `${(adjustedIndex / (moods.length - 1)) * 100}%`;
      setThumbStyles((prevStyles) => ({
        ...prevStyles,
        [indicator]: { left: leftPosition, backgroundColor: selectedMood.color }
      }));
    }
  };

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    if (!dynamicMessage) {
      setDynamicMessage(getRandomMessage());
    }
  }, [dynamicMessage]);

  const aiMessages = {
    message1: `How's it going today? `,
    message2: `How’s life treating you today? `,
    message3: `How’s your day going?`
  }

  const getRandomMessage = () => {
    const messages = Object.values(aiMessages);
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  };

  const getWellnessTags = () => {
    try {
      API.Survey.WellnessTagsList()
        .then((response) => {

          if (response.data.status === 1) {
            const checkStatus = response.data.data_all;
            if (checkStatus.length > 0) {
              setWellnessTagsList(checkStatus);
            }
          }
        })
    } catch (error) {
      console.log(error);
    }
  }


  return (
    // <div className="min-h-screen">
    //   <Sidebar />
    //   {loading ? (
    //     <figure>
    //       <div className="dot white"></div>
    //       <div className="dot"></div>
    //       <div className="dot"></div>
    //       <div className="dot"></div>
    //       <div className="dot"></div>
    //     </figure>
    //   ) : (
    //     <div className="toggleIcon">
    //       <div className="container-fluid">
    //         <div className="row list gx-3">
    //           <div className="navbar-Wrap">
    //             <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
    //               <h2 className="heading">
    //                 Welcome
    //                 <span className="secheading">
    //                   {" "}
    //                   {UserData?.userdata?.name}!
    //                 </span>
    //               </h2>
    //               <Header />
    //             </nav>
    //           </div>{" "}
    //           {/* Header*/}
    //           <div className="col-lg-12 col-xl-9 mb-2">
    //             <div className="row">
    //               {CurrentPlan?.find((item) => item?.features === "Session")
    //                 ?.is_available !== 1 &&
    //               checkDate(
    //                 new Date(
    //                   CurrentPlan.find(
    //                     (item) => item?.features === "Session"
    //                   )?.endDate
    //                 )
    //               ) ? (
    //                 <div className="col-lg-12 col-xl-6 mb-2 list-item">
    //                   <div className="dashboard-inner p-2 h-100">
    //                     <div className="row h-100">
    //                       <div className="col-md-6">
    //                         <div className="time-text-left mr-4 h-100">
    //                           <div className="dashboard-img-div h-100">
    //                             <span className="img">
    //                               <img src={BookSession} alt="Book A Session" />
    //                             </span>
    //                           </div>
    //                         </div>
    //                       </div>
    //                       <div className="col-md-6 mb-2">
    //                         <div className="time-text-right">
    //                           <div className="dashboard-text-div">
    //                             <h3 className="secheading mt-3 mb-2">
    //                               Book A Session
    //                             </h3>
    //                             <p className="my-2">
    //                               Skip the commute, not the support. Book your
    //                               online therapy session – your mental gym,
    //                               accessible anytime.
    //                             </p>
    //                             <Button
    //                               className="button_common m-0 mb-2 mt-3"
    //                               onClick={() =>
    //                                 handlePayment(
    //                                   "Session",
    //                                   CurrentPlan.find(
    //                                     (item) => item?.features === "Session"
    //                                   )
    //                                 )
    //                               }
    //                             >
    //                               <span>
    //                                 <svg
    //                                   width="24"
    //                                   height="24"
    //                                   viewBox="0 0 24 24"
    //                                   fill="none"
    //                                   xmlns="http://www.w3.org/2000/svg"
    //                                 >
    //                                   {" "}
    //                                   <rect
    //                                     x="5.73685"
    //                                     y="12.0526"
    //                                     width="12.5263"
    //                                     height="8.94737"
    //                                     rx="2"
    //                                     stroke="#333333"
    //                                     strokeWidth="2"
    //                                     strokeLinecap="round"
    //                                     strokeLinejoin="round"
    //                                   />{" "}
    //                                   <path
    //                                     d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368"
    //                                     stroke="#333333"
    //                                     strokeWidth="2"
    //                                     strokeLinecap="round"
    //                                     strokeLinejoin="round"
    //                                   />{" "}
    //                                 </svg>
    //                               </span>
    //                               unlock Features
    //                             </Button>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               ) : (
    //                 <>
    //                   <JoinSession
    //                     currentSession={currentSession}
    //                     joinSessionBtn={joinSessionBtn}
    //                     therapistData={therapistData}
    //                   />

    //                   <div className="col-lg-12 col-xl-6 mb-2 col-xxl-3 list-item">
    //                     <div className="dashboard-inner p-3">
    //                       <div className="row h-100">
    //                         <div className="col-md-12 position-relative pb-4">
    //                           <div className="time-text-right">
    //                             <div className="dashboard-text-div">
    //                               <h3 className="secheading mb-2">
    //                                 Updates From Therapist
    //                               </h3>
    //                               <p className="my-3">
    //                                 Attend all sessions for speedier recovery.
    //                               </p>
    //                               {therapistData?.fullname ? (
    //                                 <>
    //                                   <div className="update-therapist-box d-flex justfy-content-between justify-content-lg-around align-items-center py-2 mb-1 pt-0">
    //                                     <div className="name-profile">
    //                                       <p className="text-center mx-auto d-flex justify-content-center aline-items-center">
    //                                         <img
    //                                           src={
    //                                             therapistData?.image
    //                                               ? therapistData?.image
    //                                               : AiTherapist
    //                                           }
    //                                           alt="PlayGround"
    //                                         />
    //                                       </p>
    //                                       <p className="fw-bold text-center px-2">
    //                                         {therapistData?.fullname}
    //                                       </p>
    //                                     </div>
    //                                     <div className="activity-messages">
    //                                       <p className="mt-0 fw-bold d-flex align-items-center">
    //                                         <span>
    //                                           {ActivityCount
    //                                             ? ActivityCount
    //                                             : "0"}
    //                                         </span>
    //                                         Activity
    //                                       </p>
    //                                       <p className="mt-0 fw-bold d-flex align-items-center">
    //                                         <span>
    //                                           {MessagecntCount
    //                                             ? MessagecntCount
    //                                             : "0"}
    //                                         </span>
    //                                         Messages
    //                                       </p>
    //                                     </div>
    //                                   </div>

    //                                   <Button className="button_common m-0 float-end position-absolute bottom-0 dash-button">
    //                                     <NavLink
    //                                       to={AdminRoute?.Common?.MyTherapist}
    //                                     >
    //                                       View Details
    //                                     </NavLink>
    //                                   </Button>
    //                                 </>
    //                               ) : (
    //                                 <>
    //                                   <div className="upcoming-session-box d-flex justify-content-between align-items-center px-3 py-2 mb-3">
    //                                     <div className="hide-session">
    //                                       <p className="my-2">
    //                                         Select Therapist First
    //                                       </p>
    //                                     </div>
    //                                   </div>
    //                                   <Button className="button_common m-0 float-end position-absolute bottom-0 dash-button">
    //                                     <NavLink
    //                                       to={`${AdminRoute?.Common?.Therapist}`}
    //                                     >
    //                                       Select a Therapist
    //                                     </NavLink>
    //                                   </Button>
    //                                 </>
    //                               )}
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </>
    //               )}

    //               {/* dashboard 2 sections End*/}
    //               <div className="col-lg-12 mb-2 col-xl-12 col-xxl-6 list-item">
    //                 <div className="dashboard-inner p-3">
    //                   <div className="row">
    //                     <div className="dashboard-text-div">
    //                       <h3 className="secheading">Wellness Tracker</h3>
    //                       <p className="mb-1">How are you feeling today?</p>
    //                       <div className="row">
    //                         <div className="col-md-6 mb-2 wellness-tracker-border">
    //                           <p className="mb-3 fw-semibold">Mood tracker</p>
    //                           <h4 className="mb-0 wellness-heading dm-serif-font">
    //                             Joy
    //                           </h4>
    //                           <div className="col-12 wellness-tracker-slider mb-3">
    //                             <div className="row px-3">
    //                               <div className="col-12 slider-input position-relative px-0">
    //                                 <input
    //                                   type="range"
    //                                   className="form-range text-black"
    //                                   id="typeinp"
    //                                   min="0"
    //                                   max="100"
    //                                   name="answer_score"
    //                                   value={moodTraker.Joy}
    //                                   onChange={(e) => [
    //                                     setMoodTraker({
    //                                       ...moodTraker,
    //                                       Joy: e.target.value,
    //                                     }),
    //                                     handlewellness("joy", e.target.value),
    //                                   ]}
    //                                 />
    //                               </div>
    //                             </div>
    //                             <div className="d-flex justify-content-between w-100 wellness-tracker-text">
    //                               <p className=" w-18 text-start progress-bar_text my-0">
    //                                 Low
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 Moderate
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 High
    //                               </p>
    //                             </div>
    //                           </div>

    //                           <h4 className="mb-0 wellness-heading dm-serif-font">
    //                             Drive
    //                           </h4>
    //                           <div className="col-12 wellness-tracker-slider mb-3">
    //                             <div className="row px-3">
    //                               <div className="col-12 slider-input position-relative px-0">
    //                                 <input
    //                                   type="range"
    //                                   className="form-range text-black"
    //                                   id="typeinp"
    //                                   min="0"
    //                                   max="100"
    //                                   name="answer_score"
    //                                   value={moodTraker.drive}
    //                                   onChange={(e) => [
    //                                     setMoodTraker({
    //                                       ...moodTraker,
    //                                       drive: e.target.value,
    //                                     }),
    //                                     handlewellness("drive", e.target.value),
    //                                   ]}
    //                                 />
    //                               </div>
    //                             </div>
    //                             <div className="d-flex justify-content-between w-100 wellness-tracker-text">
    //                               <p className=" w-18 text-start progress-bar_text my-0">
    //                                 Low
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 Moderate
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 High
    //                               </p>
    //                             </div>
    //                           </div>

    //                           <h4 className="mb-0 wellness-heading dm-serif-font">
    //                             Stress
    //                           </h4>
    //                           <div className="col-12 wellness-tracker-slider">
    //                             <div className="row px-3">
    //                               <div className="col-12 slider-input position-relative px-0">
    //                                 <input
    //                                   type="range"
    //                                   className="form-range text-black"
    //                                   id="typeinp"
    //                                   min="0"
    //                                   max="100"
    //                                   name="answer_score"
    //                                   value={moodTraker.stress}
    //                                   onChange={(e) => [
    //                                     setMoodTraker({
    //                                       ...moodTraker,
    //                                       stress: e.target.value,
    //                                     }),
    //                                     handlewellness(
    //                                       "stress",
    //                                       e.target.value
    //                                     ),
    //                                   ]}
    //                                 />
    //                               </div>
    //                             </div>
    //                             <div className="d-flex justify-content-between w-100 wellness-tracker-text">
    //                               <p className=" w-18 text-start progress-bar_text my-0">
    //                                 Low
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 Moderate
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 High
    //                               </p>
    //                             </div>
    //                           </div>
    //                         </div>
    //                         <div className="col-md-6 mb-2">
    //                           <p className="mb-3 fw-semibold">
    //                             Lifestyle Tracker
    //                           </p>
    //                           <h6 className="mb-0 wellness-heading dm-serif-font">
    //                             Food Intake
    //                           </h6>
    //                           <div className="col-12 wellness-tracker-slider mb-3">
    //                             <div className="row px-3">
    //                               <div className="col-12 slider-input position-relative px-0">
    //                                 <input
    //                                   type="range"
    //                                   className="form-range text-black"
    //                                   id="typeinp"
    //                                   min="0"
    //                                   max="100"
    //                                   name="answer_score"
    //                                   value={lifeStyleTraker.fodIntake}
    //                                   onChange={(e) => [
    //                                     setLifeStyleTraker({
    //                                       ...lifeStyleTraker,
    //                                       fodIntake: e.target.value,
    //                                     }),
    //                                     handlewellness(
    //                                       "fodIntake",
    //                                       e.target.value
    //                                     ),
    //                                   ]}
    //                                 />
    //                               </div>
    //                             </div>
    //                             <div className="d-flex justify-content-between w-100 wellness-tracker-text">
    //                               <p className=" w-18 text-start progress-bar_text my-0">
    //                                 Poor
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 Adequate
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 Excessive
    //                               </p>
    //                             </div>
    //                           </div>

    //                           <h4 className="mb-0 wellness-heading dm-serif-font">
    //                             Sleep
    //                           </h4>
    //                           <div className="col-12 wellness-tracker-slider mb-3">
    //                             <div className="row px-3">
    //                               <div className="col-12 slider-input position-relative px-0">
    //                                 <input
    //                                   type="range"
    //                                   className="form-range text-black"
    //                                   id="typeinp"
    //                                   min="0"
    //                                   max="100"
    //                                   name="answer_score"
    //                                   value={lifeStyleTraker.sleep}
    //                                   onChange={(e) => [
    //                                     setLifeStyleTraker({
    //                                       ...lifeStyleTraker,
    //                                       sleep: e.target.value,
    //                                     }),
    //                                     handlewellness("sleep", e.target.value),
    //                                   ]}
    //                                 />
    //                               </div>
    //                             </div>
    //                             <div className="d-flex justify-content-between w-100 wellness-tracker-text">
    //                               <p className=" w-18 text-start progress-bar_text my-0">
    //                                 Poor
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 Adequate
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 Excessive
    //                               </p>
    //                             </div>
    //                           </div>

    //                           <h4 className="mb-0 wellness-heading dm-serif-font">
    //                             Activity
    //                           </h4>
    //                           <div className="col-12 wellness-tracker-slider">
    //                             <div className="row px-3">
    //                               <div className="col-12 slider-input position-relative px-0">
    //                                 <input
    //                                   type="range"
    //                                   className="form-range text-black"
    //                                   id="typeinp"
    //                                   min="0"
    //                                   max="100"
    //                                   name="answer_score"
    //                                   value={lifeStyleTraker?.activity}
    //                                   onChange={(e) => [
    //                                     setLifeStyleTraker({
    //                                       ...lifeStyleTraker,
    //                                       activity: e.target.value,
    //                                     }),
    //                                     handlewellness(
    //                                       "activity",
    //                                       e.target.value
    //                                     ),
    //                                   ]}
    //                                 />
    //                               </div>
    //                             </div>
    //                             <div className="d-flex justify-content-between w-100 wellness-tracker-text">
    //                               <p className=" w-18 text-start progress-bar_text my-0">
    //                                 Poor
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 Adequate
    //                               </p>
    //                               <p className=" w-17 text-center progress-bar_text my-0">
    //                                 Excessive
    //                               </p>
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="row">
    //               <div className="col-md-12 col-lg-8">
    //                 <h3 className="secheading mt-3">Your Support System</h3>
    //                 <p className="mb-3 text-muted">
    //                   A friend in need is a friend in deed, and we intend to be
    //                   that one for you.
    //                 </p>
    //               </div>
    //             </div>

    //             <div className="row">
    //               <div className="col-md-12 col-lg-4 mb-2 mb-lg-0">
    //                 <div className="dashboard-inner p-2 pb-5 h-100 dashboard-inner-boxes">
    //                   {CurrentPlan?.find(
    //                     (item) => item?.features === "Therapist"
    //                   )?.is_available !== 1 &&
    //                   checkDate(
    //                     new Date(
    //                       CurrentPlan?.find(
    //                         (item) => item?.features === "Therapist"
    //                       )?.endDate
    //                     )
    //                   ) ? (
    //                     <>
    //                       <div className="dashboard-img-div">
    //                         <span className="img">
    //                           <img src={AiTherapist} alt="PlayGround" />
    //                         </span>
    //                       </div>
    //                       <div className="dashboard-text-div yellow-gradient px-3">
    //                         <h3 className="secheading mt-3">Ai Therapist</h3>
    //                         <p className="mb-2">
    //                           Chat anytime with your psychological companion -
    //                           instant, affordable insights for your peace of
    //                           mind.
    //                         </p>
    //                         <Button
    //                           className="button_common m-0 mb-3 position-absolute bottom-0"
    //                           onClick={() =>
    //                             handlePayment(
    //                               "Aitherapist",
    //                               CurrentPlan?.find(
    //                                 (item) => item?.features === "Therapist"
    //                               )
    //                             )
    //                           }
    //                         >
    //                           <span>
    //                             <svg
    //                               width="24"
    //                               height="24"
    //                               viewBox="0 0 24 24"
    //                               fill="none"
    //                               xmlns="http://www.w3.org/2000/svg"
    //                             >
    //                               {" "}
    //                               <rect
    //                                 x="5.73685"
    //                                 y="12.0526"
    //                                 width="12.5263"
    //                                 height="8.94737"
    //                                 rx="2"
    //                                 stroke="#333333"
    //                                 strokeWidth="2"
    //                                 strokeLinecap="round"
    //                                 strokeLinejoin="round"
    //                               />{" "}
    //                               <path
    //                                 d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368"
    //                                 stroke="#333333"
    //                                 strokeWidth="2"
    //                                 strokeLinecap="round"
    //                                 strokeLinejoin="round"
    //                               />{" "}
    //                             </svg>
    //                           </span>
    //                           unlock features
    //                         </Button>
    //                       </div>
    //                     </>
    //                   ) : (
    //                     <div className="cursor_pointer">
    //                       <div className="dashboard-img-div ">
    //                         <span className="img">
    //                           <img src={AiTherapist} alt="PlayGround" />
    //                         </span>
    //                       </div>
    //                       <div
    //                         className="dashboard-text-div yellow-gradient px-3"
    //                         onClick={() =>
    //                           navigate(`../${AdminRoute?.Common?.Aitherapist}`)
    //                         }
    //                       >
    //                         <h3 className="secheading mt-3">Ai Therapist</h3>
    //                         <p>
    //                           Chat anytime with your psychological companion -
    //                           instant, affordable insights for your peace of
    //                           mind.
    //                         </p>
    //                       </div>
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>

    //               <div className="col-md-12 col-lg-4 mb-2 mb-lg-0">
    //                 {CurrentPlan?.find((item) => item?.features === "Groups")
    //                   ?.is_available !== 1 &&
    //                 checkDate(
    //                   new Date(
    //                     CurrentPlan?.find(
    //                       (item) => item?.features === "Groups"
    //                     )?.endDate
    //                   )
    //                 ) ? (
    //                   <div className="dashboard-inner p-2 pb-5 h-100 dashboard-inner-boxes">
    //                     <div className="dashboard-img-div">
    //                       <span className="img">
    //                         <img src={Groups} alt="AiTherapist" />
    //                       </span>
    //                     </div>
    //                     <div className="dashboard-text-div px-3">
    //                       <h3 className="secheading mt-3"> Groups</h3>
    //                       <p className="mb-2">
    //                         Find your tribe, share challenges, and unlock
    //                         strength with other driven professionals.
    //                       </p>
    //                       <Button
    //                         className="button_common m-0 mb-3 position-absolute bottom-0"
    //                         onClick={() =>
    //                           handlePayment(
    //                             "Groups",
    //                             CurrentPlan.find(
    //                               (item) => item.features === "Groups"
    //                             )
    //                           )
    //                         }
    //                       >
    //                         <span>
    //                           <svg
    //                             width="24"
    //                             height="24"
    //                             viewBox="0 0 24 24"
    //                             fill="none"
    //                             xmlns="http://www.w3.org/2000/svg"
    //                           >
    //                             {" "}
    //                             <rect
    //                               x="5.73685"
    //                               y="12.0526"
    //                               width="12.5263"
    //                               height="8.94737"
    //                               rx="2"
    //                               stroke="#333333"
    //                               strokeWidth="2"
    //                               strokeLinecap="round"
    //                               strokeLinejoin="round"
    //                             />{" "}
    //                             <path
    //                               d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368"
    //                               stroke="#333333"
    //                               strokeWidth="2"
    //                               strokeLinecap="round"
    //                               strokeLinejoin="round"
    //                             />{" "}
    //                           </svg>
    //                         </span>
    //                         unlock features
    //                       </Button>
    //                     </div>
    //                   </div>
    //                 ) : (
    //                   <></>
    //                 )}

    //                 {CurrentPlan?.find((item) => item.features === "Groups")
    //                   ?.is_available !== 1 &&
    //                 checkDate(
    //                   new Date(
    //                     CurrentPlan?.find(
    //                       (item) => item.features === "Groups"
    //                     )?.endDate
    //                   )
    //                 ) ? (
    //                   <></>
    //                 ) : (
    //                   <div
    //                     className="dashboard-inner p-2 h-100 dashboard-inner-boxes"
    //                     onClick={() =>
    //                       navigate(`../${AdminRoute?.Common?.Groupchat}`)
    //                     }
    //                   >
    //                     <div className="dashboard-img-div">
    //                       <span className="img">
    //                         <img src={Groups} alt="AiTherapist" />
    //                       </span>
    //                     </div>
    //                     <div className="dashboard-text-div px-3">
    //                       <h3 className="secheading mt-3"> Groups</h3>
    //                       <p>
    //                         Find your tribe, share challenges, and unlock
    //                         strength with other driven professionals.
    //                       </p>
    //                       <div className="row my-2">
    //                         {FocussedData &&
    //                           FocussedData?.slice(0, 3).map((slide, i) => (
    //                             <React.Fragment key={1}>
    //                               <div className="col-3">
    //                                 <span className="group-img position-relative">
    //                                   {/*  <span className='group-messages position-absolute'></span>*/}
    //                                   <img
    //                                     src={
    //                                       slide.logo
    //                                         ? `${baseApi.baseurl}/images/${slide?.logo}`
    //                                         : `${process.env.REACT_APP_BASE_URL}images/group-default.jpg`
    //                                     }
    //                                     alt="AiTherapist"
    //                                   />
    //                                 </span>
    //                               </div>
    //                             </React.Fragment>
    //                           ))}
    //                         <div
    //                           className="col-3 view-all-btn d-flex align-items-center cursor_pointer"
    //                           onClick={() =>
    //                             navigate(`../${AdminRoute.Common.Join_Group}`)
    //                           }
    //                         >
    //                           View All
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>

    //               <div className="col-md-12 col-lg-4">
    //                 <div className="dashboard-inner p-2 mb-2 h-100 dashboard-inner-boxes">
    //                   {CurrentPlan.find(
    //                     (item) => item.features === "Playground"
    //                   )?.is_available !== 1 &&
    //                   checkDate(
    //                     new Date(
    //                       CurrentPlan.find(
    //                         (item) => item.features === "Playground"
    //                       )?.endDate
    //                     )
    //                   ) ? (
    //                     <>
    //                       <div className="dashboard-img-div">
    //                         <span className="img">
    //                           <img src={PlayGround} alt="Groups" />
    //                         </span>
    //                       </div>
    //                       <div className="dashboard-text-div px-3">
    //                         <h3 className="secheading mt-3">Playground</h3>
    //                         <p>
    //                           Unleash your creativity and recharge your mind
    //                           with playful exercises designed for peak
    //                           performance.
    //                         </p>
    //                         <Button
    //                           className="button_common m-0 mb-2 mt-3"
    //                           onClick={() =>
    //                             handlePayment(
    //                               "Plaground",
    //                               CurrentPlan.find(
    //                                 (item) => item.features === "Playground"
    //                               )
    //                             )
    //                           }
    //                         >
    //                           <span>
    //                             <svg
    //                               width="24"
    //                               height="24"
    //                               viewBox="0 0 24 24"
    //                               fill="none"
    //                               xmlns="http://www.w3.org/2000/svg"
    //                             >
    //                               {" "}
    //                               <rect
    //                                 x="5.73685"
    //                                 y="12.0526"
    //                                 width="12.5263"
    //                                 height="8.94737"
    //                                 rx="2"
    //                                 stroke="#333333"
    //                                 strokeWidth="2"
    //                                 strokeLinecap="round"
    //                                 strokeLinejoin="round"
    //                               />{" "}
    //                               <path
    //                                 d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368"
    //                                 stroke="#333333"
    //                                 strokeWidth="2"
    //                                 strokeLinecap="round"
    //                                 strokeLinejoin="round"
    //                               />{" "}
    //                             </svg>
    //                           </span>
    //                           unlock features
    //                         </Button>
    //                       </div>
    //                     </>
    //                   ) : (
    //                     <div
    //                       className="cursor_pointer"
    //                       onClick={() =>
    //                         navigate(`../${AdminRoute.Common.Playground}`)
    //                       }
    //                     >
    //                       <div className="dashboard-img-div">
    //                         <span className="img">
    //                           <img src={PlayGround} alt="Groups" />
    //                         </span>
    //                       </div>
    //                       <div className="dashboard-text-div px-3">
    //                         <h3 className="secheading mt-3">Playground</h3>
    //                         <p>
    //                           Unleash your creativity and recharge your mind
    //                           with playful exercises designed for peak
    //                           performance.
    //                         </p>
    //                       </div>
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           {/* col-lg-9 */}
    //           <div className="col-lg-12 col-xl-3 mb-2 list-item">
    //             <div className="dashboard-inner p-3 mb-2 h-100">
    //               <h3 className="secheading mb-3">Digital Notes</h3>
    //               {/* <div className="dashboard-img-div h-50">
    //               <span className="img">
    //               <img src={DigitalNotes} alt="DigitalNotes" />
    //               </span>
    //             </div> */}
    //               {CurrentPlan.find((item) => item.features === "Notes")
    //                 ?.is_available !== 1 &&
    //               checkDate(
    //                 new Date(
    //                   CurrentPlan.find(
    //                     (item) => item.features === "Notes"
    //                   )?.endDate
    //                 )
    //               ) ? (
    //                 <div className="dashboard-text-div">
    //                   <p className="m-2 mx-0">
    //                     Track your progress and master your mental fitness with
    //                     a personalized journaling tool and mood tracker.{" "}
    //                   </p>
    //                   <h3 className="secheading my-4 text-center">
    //                     “Journaling is wonderful pathway is self awareness’
    //                   </h3>
    //                   <div className="text-center ">
    //                     <Button
    //                       type="submit"
    //                       className="button_common mt-3 m-auto"
    //                       onClick={() =>
    //                         handlePayment(
    //                           "Digitalnotes",
    //                           CurrentPlan.find(
    //                             (item) => item.features === "Notes"
    //                           )
    //                         )
    //                       }
    //                     >
    //                       <span>
    //                         <svg
    //                           className="w-8 h-8 xl:w-5 xl:h-5"
    //                           viewBox="0 0 24 24"
    //                           fill="currentColor"
    //                           xmlns="http://www.w3.org/2000/svg"
    //                         >
    //                           <path d="M21.266 20.998H2.73301C2.37575 20.998 2.04563 20.8074 1.867 20.498C1.68837 20.1886 1.68838 19.8074 1.86701 19.498L11.133 3.49799C11.3118 3.1891 11.6416 2.9989 11.9985 2.9989C12.3554 2.9989 12.6852 3.1891 12.864 3.49799L22.13 19.498C22.3085 19.8072 22.3086 20.1882 22.1303 20.4975C21.9519 20.8069 21.6221 20.9976 21.265 20.998H21.266ZM12 5.99799L4.46901 18.998H19.533L12 5.99799ZM12.995 14.999H10.995V9.99799H12.995V14.999Z"></path>
    //                           <path d="M11 16H13V18H11V16Z"></path>
    //                         </svg>
    //                       </span>
    //                       unlock features
    //                     </Button>
    //                   </div>
    //                 </div>
    //               ) : (
    //                 <>
    //                   <div className="row px-2 digital-notes-wrap">
    //                     <div className="col-12 px-0">
    //                       {loadingList ? (
    //                         <div className="container_loader"></div>
    //                       ) : DigitalNotesList.length === 0 ? (
    //                         <div className="alert alert-warning" role="alert">
    //                           Data Not Found
    //                         </div>
    //                       ) : (
    //                         DigitalNotesList &&
    //                         DigitalNotesList.map((val) => {
    //                           return (
    //                             <div className="digital-notes px-2 py-3 mt-2 position-relative">
    //                               {val?.response &&
    //                               val?.response?.responce &&
    //                               val?.is_seen === 0 ? (
    //                                 <p className="notes-count position-absolute">
    //                                   1
    //                                 </p>
    //                               ) : (
    //                                 <></>
    //                               )}
    //                               <h6>{val?.title}</h6>
    //                               <p className="text-grey">
    //                                 {moment(val?.createdAt).format("LL")}
    //                               </p>
    //                               <p className="mb-0">
    //                                 {val?.description.length > 40
    //                                   ? `${val?.description.substring(
    //                                       0,
    //                                       40
    //                                     )}....`
    //                                   : val?.description}
    //                               </p>
    //                             </div>
    //                           );
    //                         })
    //                       )}
    //                     </div>
    //                   </div>
    //                   <div className="add-notes-btn position-absolute">
    //                     <NavLink to={AdminRoute.Common.DigitalNotes}>
    //                       <p className="text-white fw-bold h2 text-center">+</p>
    //                     </NavLink>
    //                   </div>
    //                 </>
    //               )}
    //             </div>
    //           </div>
    //           {/* col-lg- */}
    //         </div>

    //         <Footer />
    //       </div>
    //     </div>
    //   )}

    // </div>
    <div className="flex flex-col max-h-screen max-w-screen bg-white mx-0 w-full">
      <div className="flex-grow bg-white m-0 w-full p-0">
        <Header />
        <Sidebar />
        <div className="flex-grow flex flex-col bg-white md:flex-col lg:flex-row md:ml-[6%] h-full md:mr-4 md:my-8">
          <div className="w-full pr-1 md:pr-0 md:ml-[6%] items-end overflow-visible relative mb-4 flex lg:hidden md:mb-0 bg-gradient-to-b  from-[#FFFFFF] to-[#FBD2D2]">
            <img src={AiImage} alt="AiImage" className="h-auto object-cover " />
            <div class="absolute top-[4%]  right-[3%] md:right-[23%] w-[69%] md:w-[54%] h-[62%] bg-gradient-to-t from-[#F5F5F9] via-[#F8D27F] to-[#F5F6FB]  px-2 md:px-4 pt-4 overflow-y-auto text-center text-gray-800 text-base font-sans">
              <div class="flex flex-col items-start overflow-hidden">
                <div class="bg-white/60 text-gray-800 px-2 py-2 md:px-4 md:py-2 rounded-full rounded-bl-none shadow-sm text-xs md:text-sm leading-relaxed relative text-left">
                  <MovingComponent type="typewriter"
                    dataText={[
                      `Hey ${UserData?.userdata?.name}! ${dynamicMessage}`
                    ]} />
                </div>
                <div class="bg-white/80 border border-gray-300 rounded-full lg:px-4 flex justify-between px-2  py-0.5 lg:p-2  shadow-sm absolute bottom-1 w-56 md:w-96 lg:w-96 text-xs md:text-sm overflow-hidden text-gray-400 text-left cursor-pointer" onClick={() => navigate(`../${AdminRoute?.Auth.AiAsistance}`)}>
                  Click to chat<IoMdSend size={16} color="#000" className="md:mt-1" />
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0">
              <img src={grass} alt="grass" className="w-full h-auto object-cover rounded-lg" />
            </div>
          </div>
          <div
            className="w-full h-auto lg:w-2/5 md:m-0 flex justify-center items-center flex-col md:block pb-4 md:pb-0"
            style={{
              background: `url(${part1})`,
              backgroundSize: 'auto',
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="flex-grow flex float-right align-right mt-2">
              <div className="md:mr-16 rounded-3xl overflow-hidden shadow-md bg-white border border-[#F2F2F2] mr-2 justify-center md:justify-end items-center md:items-end" >
                <div className=" py-3 px-6 text-center">
                  <h2 className="font-bold text-sm md:text-xl mb-2">Upcoming Session</h2>
                  <p className="text-gray-800 text-xs mb-2 ">
                    Attend all session for speedier recovery
                  </p>
                  {currentSession?.date ? (
                    <div className="h-16 rounded-3xl overflow-hidden" style={{ border: '1px solid #F8D68B' }}>
                      <div className="flex flex-grow items-center justify-center">
                        <div className="h-16 flex flex-col items-center justify-center w-1/2" style={{ borderRight: '1px solid #B0AAAA' }}>
                          <span className="font-bold text-sm capitalize">{currentSession?.month}</span>
                          <span className="font-bold text-sm">{currentSession &&
                            currentSession?.date
                              ?.split("T")[0]
                              ?.split("-")[2]}</span>
                        </div>
                        <div className="h-12 md:h-16 flex flex-col items-center justify-center w-1/2">
                          <span className="font-bold text-sm">{currentSession?.day}</span>
                          <span className="font-bold text-sm"> {currentSession?.timestart}</span>
                        </div>
                      </div>
                    </div>) : null}
                  {currentSession?.date && joinSessionBtn ? (
                    <button
                      className="mt-3 h-8 bg-[#F5BD41] border border-[#F5BD41] text-black py-2 px-4 rounded-lg  font-semibold align-middle text-center text-xs hover:bg-[#F5BD41]"
                      onClick={() => renderActionButton()}
                    >
                      Join Session
                    </button>
                  ) : (
                    <NavLink
                      to={`../${AdminRoute?.Common?.Appointment_scheduler}/?key=true`}
                      className=""
                    >
                      <button className="mt-3 h-8 bg-[#F5BD41] border border-[#F5BD41] text-black py-2 px-4 rounded-lg  font-semibold align-middle text-center text-xs">
                        Book a Session
                      </button>
                    </NavLink>
                  )}
                  {/* {!currentSession ? (
                  <p className="text-black py-2 px-4">No Sessions Available.</p>
                ) : null} */}
                </div>
              </div>
            </div>
            <div className="flex-grow flex float-right md:float-left mt-2">
              <div className="md:ml-24 mr-2 rounded-[40px] overflow-hidden shadow-md bg-white border border-[#F2F2F2] mt-2">
                <div className="py-3 px-6 text-center">
                  <h2 className="font-bold text-sm md:text-lg mb-2">UPDATE FROM THERAPIST</h2>
                  <div className="h-28">
                    <div className="flex flex-grow items-center justify-center">
                      <div className="flex flex-col items-center justify-center w-1/3">
                        <img src={playgroundImage} className="h-20 w-auto mb-1" />
                        <span className="font-semibold text-md mb-2">SMIRTHI C</span>
                      </div>
                      <div className="h-20 flex flex-col items-center justify-center flex-grow space-x-4">
                        <div className="flex flex-col md:flex-row">
                          <div className="flex items-center justify-center mr-2">
                            <div class="relative justify-center items-center hidden md:block">
                              <IoIosSend size={40} />
                              <span class="absolute animate-ping top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-full"></span>
                              <span class="absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-full">{MessagecntCount ? MessagecntCount : "0"}</span>
                            </div>
                            <div class="relative justify-center items-center md:hidden pb-1">
                              <IoIosSend size={30} />
                              <span class="absolute animate-ping top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full"></span>
                              <span class="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-[8px] font-bold text-white bg-red-500 rounded-full">{MessagecntCount ? MessagecntCount : "0"}</span>
                            </div>
                            <span class="text-gray-900 text-md ml-1">Messages</span>
                          </div>
                          <div className="flex items-center justify-center">
                            <div className="relative items-center hidden md:flex">
                              <TbBulb size={40} />
                              <span className="absolute animate-ping top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-full"></span>
                              <span className="absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-full">{ActivityCount ? ActivityCount : 0}</span>
                            </div>
                            <div className="relative items-center  md:hidden">
                              <TbBulb size={30} />
                              <span className="absolute animate-ping top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full"></span>
                              <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-[8px] font-bold text-white bg-red-500 rounded-full">{ActivityCount ? ActivityCount : 0}</span>
                            </div>
                            <span className="text-gray-900 text-md ml-1">Activity</span>
                          </div>
                        </div>
                        <div className="mt-2">
                          <button className="mt-2 h-8 bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-xs" onClick={() =>
                            navigate(
                              `../${AdminRoute?.Common?.MyTherapist}`
                            )
                          }>
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow rounded-[50px] mt-16 md:ml-[6%] md:mt-20 lg:mt-2 pb-4" style={{ background: "radial-gradient(#ffa94721, #80bab51f, #ffde6a1a)" }}>
            <div className="w-full flex items-center justify-center overflow-visible align-bottom">
              <div className="w-40 flex items-center justify-center align-bottom absolute lg:top-8 top-120 right-100">
                <img src={moodTrackerHeading} alt="AiImage" className="h-auto w-auto object-contain" />
              </div>
            </div>
            <div className="flex-grow flex flex-col md:flex-row md:mx-6 mt-12 md:mt-6">
              <div className=" w-full md:w-3/5 flex justify-center items-center md:justify-start md:items-start  md:mx-0">
                <div className="flex flex-col justify-center items-center md:justify-start md:items-start mt-4 md:mt-0 md:mx-0">
                  <div className="flex flex-col items-start p-0 gap-1">
                    <div class="text-black text-[11px] md:text-sm font-bold h-4">
                      Feeling more woohoo or meh today?
                    </div>
                    <div className="flex flex-col gap-1 h-18 items-center">
                      <div className="flex justify-center items-center gap-3 w-auto h-16">
                        {moods.map((mood, index) => (
                          <img
                            key={index}
                            src={mood.img}
                            className={`h-12 w-auto cursor-pointer`}
                            onClick={() => handlewellness(`happy`, mood.value)}
                            alt={`Mood ${mood.value}`}
                            style={{
                              transform: selectedMood['happy'] === mood.value ? 'scale(1.20)' : 'scale(1)',
                              pointerEvents: isDisabled['happy'] ? 'none' : 'pointer', // Enable clicking initially
                              opacity: isDisabled['happy'] && selectedMood['happy'] !== mood.value ? 0.5 : 1 // Dim unselected emojis if disabled
                            }}
                          />
                        ))}
                      </div>

                      <div className="relative w-full h-3 rounded-lg flex items-center">
                        <div className="flex w-full h-3 rounded-lg">
                          <div className="flex-1 bg-[#7dba27] rounded-s-full"></div>
                          <div className="flex-1 bg-[#75cdf4]"></div>
                          <div className="flex-1 bg-[#fbde17]"></div>
                          <div className="flex-1 bg-[#f39117]"></div>
                          <div className="flex-1 bg-[#e6223f] rounded-e-full"></div>
                        </div>
                        <div
                          className="absolute w-5 h-5 rounded-full -top-1 transition-transform shadow-sm "
                          style={{
                            ...thumbStyles['happy'],
                            transform: 'translateX(-50%)',
                            border: '1px solid #777777'
                          }}
                        ></div>
                      </div>
                      <div class="text-black text-opacity-50 text-xs italic h-2 justify-center">
                        (Joy)
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-start p-0 gap-1 mt-4">
                    <div class="text-black text-opacity-83 text-[11px] md:text-sm font-bold h-4">
                      Is your energy rocket ready for takeoff?
                    </div>
                    <div className="flex flex-col gap-1 h-18 items-center">
                      <div className="flex justify-center items-center gap-3 w-auto h-16">
                        {moods.map((mood, index) => (
                          <img
                            key={index}
                            src={mood.img}
                            className={`h-12 w-auto cursor-pointer`}
                            onClick={() => handlewellness(`motivation`, mood.value)}
                            alt={`Mood ${mood.value}`}
                            style={{
                              transform: selectedMood['motivation'] === mood.value ? 'scale(1.20)' : 'scale(1)',
                              pointerEvents: isDisabled['motivation'] ? 'none' : 'pointer', // Enable clicking initially
                              opacity: isDisabled['motivation'] && selectedMood['motivation'] !== mood.value ? 0.5 : 1 // Dim unselected emojis if disabled
                            }}
                          />
                        ))}
                      </div>

                      <div className="relative w-full h-3 rounded-lg flex items-center">
                        <div className="flex w-full h-3 rounded-lg">
                          <div className="flex-1 bg-[#7dba27] rounded-s-full"></div>
                          <div className="flex-1 bg-[#75cdf4]"></div>
                          <div className="flex-1 bg-[#fbde17]"></div>
                          <div className="flex-1 bg-[#f39117]"></div>
                          <div className="flex-1 bg-[#e6223f] rounded-e-full"></div>
                        </div>
                        <div
                          className="absolute w-5 h-5 rounded-full -top-1 transition-transform shadow-sm "
                          style={{
                            ...thumbStyles['motivation'],
                            transform: 'translateX(-50%)',
                            border: '1px solid #777777'
                          }}
                        ></div>
                      </div>
                      <div class="text-black text-opacity-50 text-xs italic h-2 justify-center">
                        (Motivation)
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start p-0 gap-1 mt-4">
                    <div class="text-black text-opacity-83 text-[11px] md:text-sm font-bold h-8">
                      Feeling cool as a cucumber or like a pressure cooker?
                    </div>
                    <div className="flex flex-col gap-1 h-18 items-center">
                      <div className="flex justify-center items-center gap-3 w-auto h-16">
                        {stressMoods.map((mood, index) => (
                          <img
                            key={index}
                            src={mood.img}
                            className={`h-12 w-auto cursor-pointer`}
                            onClick={() => handlewellness(`stress`, mood.value)}
                            alt={`Mood ${mood.value}`}
                            style={{
                              transform: selectedMood['stress'] === mood.value ? 'scale(1.20)' : 'scale(1)',
                              pointerEvents: isDisabled['stress'] ? 'none' : 'pointer',
                              opacity: isDisabled['stress'] && selectedMood['stress'] !== mood.value ? 0.5 : 1
                            }}
                          />
                        ))}
                      </div>

                      <div className="relative w-full h-3 rounded-lg flex items-center">
                        <div className="flex w-full h-3 rounded-lg">
                          <div className="flex-1 bg-[#7dba27] rounded-s-full"></div>
                          <div className="flex-1 bg-[#75cdf4]"></div>
                          <div className="flex-1 bg-[#fbde17]"></div>
                          <div className="flex-1 bg-[#f39117]"></div>
                          <div className="flex-1 bg-[#e6223f] rounded-e-full"></div>
                        </div>
                        <div
                          className="absolute w-5 h-5 rounded-full -top-1 transition-transform shadow-sm "
                          style={{
                            ...thumbStyles['stress'],
                            transform: 'translateX(-50%)',
                            border: '1px solid #777777'
                          }}
                        ></div>
                      </div>
                      <div class="text-black text-opacity-50 text-xs italic h-2 justify-center">
                        (Stress)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-2/5 flex justify-center items-center md:justify-start md:items-start px-2 md:pb-0 mt-4 md:mt-0 md:max-w-96">
                <div className="w-full px-4 py-2 flex flex-col justify-center items-center md:justify-start md:items-start align-top border border-[#B0AAAA] rounded-[30px] h-full overflow-x-hidden">
                  <p className="mb-3 font-bold text-md md:text-xs">Pick up to 5 Suitable Moods</p>
                  {showTagSelection && showTagSelection ? (
                    <div className="w-full flex flex-wrap gap-2 align-top max-h-96 overflow-y-auto scrollbar-thin">
                      {wellnessTagsList && wellnessTagsList.length > 0 ? (
                        wellnessTagsList.map((record, index) => (
                          <div
                            key={index}
                            className="text-black rounded-[12px] my-0.5 mx-1 py-1.5 px-2.5 text-xs font-medium shadow-md transition-colors duration-300 bg-yellow-50 hover:bg-yellow-400 cursor-pointer active:bg-yellow-400"
                            onClick={() => insertUserWellnessTag(record.id, record.tagName)}
                          >
                            {record.tagName}
                          </div>
                        ))
                      ) : (
                        <p>No Wellness Tags to display</p>
                      )}
                    </div>
                  ) : (
                    <div className="w-full flex flex-wrap gap-2 align-top max-h-96 overflow-y-auto scrollbar-thin">
                      {selectedTags && selectedTags.length > 0 ? (
                        selectedTags.map((record, index) => (
                          <div
                            key={index}
                            className="bg-yellow-100 text-yellow-800 my-0.5 mx-1 rounded-[12px] py-1.5 px-2.5 text-xs font-medium shadow-md transition-colors duration-300 border border-yellow-300"
                          >
                            {record.tagName}
                          </div>
                        ))
                      ) : (
                        null
                      )}

                      {wellnessTagsList && wellnessTagsList.length > 0 && (
                        wellnessTagsList.map((record, index) => (
                          <div
                            key={index}
                            className="bg-yellow-50 text-black rounded-[12px] my-0.5 mx-1  py-1.5 px-2.5 text-xs font-medium shadow-md transition-colors duration-300 cursor-not-allowed"
                          >
                            {record.tagName}
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div >
      <div className="md:ml-20 mb-4 md:mr-4 z-1  ">
        <div className="md:ml-2 flex-grow flex-col pt-4 md:pt-0 md:flex-row z-5 bg-gradient-to-b  from-[#FFFFFF] to-[#FBD2D2] flex rounded-lg relative  md:space-x-4 lg:space-x-20">
          <div className="md:w-1/2 items-end overflow-visible relative mb-2 hidden lg:flex md:mb-0">
            <img src={AiImage} alt="AiImage" className="h-auto object-cover " />
            <div class="absolute top-[4%] right-[2%] lg:right-[6%] w-[70%] lg:w-[66%] h-[62%] bg-gradient-to-t from-[#F5F5F9] via-[#F8D27F] to-[#F5F6FB]  px-2 md:px-4 pt-4 overflow-y-auto text-center text-gray-800 text-base font-sans">
              <div class="flex flex-col items-start overflow-hidden">
                <div class="bg-white/60 text-gray-800 px-2 py-2 md:px-4 md:py-2 rounded-full rounded-bl-none shadow-sm text-[10px] lg:text-sm leading-relaxed relative text-left">
                  <MovingComponent type="typewriter"
                    dataText={[
                      `Hey ${UserData?.userdata?.name}! ${dynamicMessage}`
                    ]} />
                </div>
                <div class="bg-white/80 border border-gray-300 rounded-full lg:px-4 flex justify-between px-2  py-0.5 lg:p-2  shadow-sm absolute bottom-1 w-64 md:w-48 lg:w-96 text-[10px]  md:text-xs overflow-hidden text-gray-400 text-left cursor-pointer" onClick={() => navigate(`../${AdminRoute?.Common?.Aitherapist}`)}>
                  Click to chat<IoMdSend size={16} color="#000" className="mt-1 md:mt-0" />
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 hidden md:block">
              <img src={grass} alt="grass" className="w-full h-auto object-cover rounded-lg" />
            </div>
          </div>
          <div className=" w-full lg:w-3/5 flex flex-col">
            <div className="flex flex-col md:flex-row w-full h-full md:space-x-4 lg:space-x-20 mb-12">
              <div className="md:w-1/2 mb-4 md:mb-0 flex flex-col items-center justify-center overflow-hidden p-2 lg:p-4  cursor-pointer" onClick={() => navigate(`../${AdminRoute?.Common?.Groupchat}`)}>
                <img src={GroupsImage} alt="Image 2" className="w-auto h-auto object-cover" />
                <p className="text-center mt-1 font-bold text-black text-xl z-10">Groups</p>
              </div>
              <div className="md:w-1/2 flex flex-col items-center justify-center overflow-hidden p-2 pb-0 lg:p-4  cursor-pointer" onClick={() => navigate(`../${AdminRoute?.Common.Playground}`)}>
                <img src={playgroundImage} alt="Image 3" className="w-auto h-auto object-cover" />
                <p className="text-center mt-1 font-bold text-black text-xl z-10">Playground</p>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0">
              <img src={grass} alt="Bottom Image" className="w-full h-auto object-cover rounded-lg" />
            </div>
          </div>
        </div>
      </div>
      <div className="hero_area relative z-0">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
          </g>
        </svg>
      </div>



    </div >
  );
}

export default Dashboard;
