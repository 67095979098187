import React, { useState } from 'react';
import ChatBox from './Chatbox';

const GroupDetailsPage = ({ group }) => {
    console.log('groupDetails', group);
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const tabNames = ['Discussion', 'Activity', 'Resources'];

    return (
        <div className={`flex flex-col justify-center items-center rounded-xl w-full border border-[#EBE8E8] pt-4 ${group.type === 'focused' ? 'bg-gradient-to-b from-[#FFFFFF] to-[#D7E8FF]' : group.type === 'support' ? 'bg-gradient-to-b from-[#ffffff] to-[#D7FFF2]' : 'bg-white'}`}>
            <nav
                className="relative z-0 flex border rounded-xl overflow-hidden dark:border-neutral-700 outline-none"
                aria-label="Tabs"
                role="tablist"
                aria-orientation="horizontal"
            >
                {tabNames.map((tab, index) => (
                    <button
                        key={index}
                        type="button"
                        className={`${activeTab === index ? `text-white ${group.type === 'focused' ? 'bg-[#5FA5F9]' : group.type === 'support' ? 'bg-[#ABDC8C]' : 'bg-white'}` : 'bg-white'
                            } relative flex-1 border-s py-3 px-4 text-gray-500 ${group.type === 'focused' ? 'hover:text-[#5FA5F9]' : group.type === 'support' ? 'hover:text-[#ABDC8C]' : 'hover:text-gray-700'} text-sm font-medium text-center overflow-hidden outline-none`}
                        id={`tab-item-${index}`}
                        aria-selected={activeTab === index}
                        onClick={() => handleTabClick(index)}
                        role="tab"
                    >
                        {tab}
                    </button>
                ))}
            </nav>

            <div className="mt-3 w-full">
                {tabNames.map((tab, index) => (
                    <div
                        key={index}
                        id={`tab-content-${index}`}
                        role="tabpanel"
                        aria-labelledby={`tab-item-${index}`}
                        className={activeTab === index ? '' : 'hidden'}
                    >
                        <p className="text-sm pb-1 text-black font-semibold w-full text-center">
                            {index === 0
                                ? "Engage in meaningful conversations, sharing experiences, feelings, and insights, fostering a supportive community atmosphere."
                                : index === 1
                                    ? "Participate in quizzes and polls designed to deepen self-awareness and curated by group moderators."
                                    : index === 2
                                        ? "Access and contribute to a valuable repository of mental health practices and tools, enhancing personal growth and group learning"
                                        : null}
                        </p>
                        <div className='w-full max-h-[58vh] min-h-[58vh] overflow-y-auto overflow-x-hidden flex flex-grow mt-2 mb-1 border-t border-gray-300 p-2'>
                            <ChatBox activeIndex={activeTab} groupId={group._id}/>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GroupDetailsPage;
