import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import baseApi from "../../Api/config";
import API from "../../Api/Api";
import imagebg from "../../Assets/Images/DigitalNotes/TypeFeild/typeField3.png";
import imagetop from "../../Assets/Images/DigitalNotes/TypeFeild/typeField2.png";
import imagebottom from "../../Assets/Images/DigitalNotes/TypeFeild/typeField1.png";
import ThemeModal from "../ThemeModal";
import { toast } from "react-toastify";

const CreateNotes = ({ onClose }) => {
    const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const noteId = queryParams.get("id");
    const authToken = localStorage.getItem("AuthTokenClient");

    const navigate = useNavigate();
    // date time
    const CurrentTime = moment().format("LT"); // htmlFor current Time
    const CurrentDate = moment().format("L"); // htmlFor current Date
    const initialValues = {
        Title: "",
        Description: "",
    };
    const [CreateNotes, setCreateNotes] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [Theamlist, setTheamlist] = useState([]);
    const [image, setimage] = useState("");
    const [image_id, setimage_id] = useState("");
    const [loadingTheam, setLoadingTheam] = useState(false);
    const [DigitalNotesdetails, setDigitalNotesdetails] = useState([]);
    const [loadingNotes, setLoadingNotes] = useState(false);
    const [openThemeModal, setOpenThemeModal] = useState(false);

    useEffect(() => {
        if (noteId) {
            setLoadingNotes(true)
            try {
                API.Auth.DigitalNotesDetails({
                    notes_id: noteId
                }).then((response) => {
                    if (response.status === 200) {
                        setDigitalNotesdetails(response?.data?.data_all);
                        setLoadingNotes(false);
                    }
                });
            } catch (error) {
                console.log(error);
                setLoadingNotes(false);
            }
        }
    }, [])


    const CreateNoteValidate = (value) => {
        const errors = {};
        var titleregx = /^[a-zA-Z]+ [a-zA-Z]+$/;

        // if (!value?.image) {
        //   errors.image = "Theam is required";
        // }

        if (!value?.Title) {
            errors.Title = "Title is required";
            toast.error("Title is required");
        }
        // else if (!titleregx.test(value?.title)) {
        //   errors.Title = "Enter valid title";
        // }

        if (!value?.Description) {
            errors.Description = "Description is required";
            toast.error("Description is required");
        }
        // else if (!titleregx.test(value?.Description)) {
        //   errors.Description = "Enter valid Description";
        // }
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCreateNotes({
            ...CreateNotes,
            [name]: value,
        });
    };
    // api call for theam list
    const GetTheamlist = () => {
        setLoadingTheam(true)
        let data = "";
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${baseApi.baseurl}themenotes/themelist`,
            headers: {},
            data: data,
        };
        axios
            .request(config)
            .then((response) => {
                setTheamlist(response?.data?.data_all);
                handleTheamimage(response.data.data_all[0]);
                setLoadingTheam(false)
            })
            .catch((error) => {
                console.log(error);
                setLoadingTheam(false)
            });
    };

    useEffect(() => {
        GetTheamlist();
    }, []);

    // function to add teams in api
    const handleTheamimage = (val) => {
        setimage(val?.backgroundimageurl);
        setimage_id(val?._id);
        // toast.success('Theme Applied Successfully');
    };

    //api call for create digital notes
    const CreateDigitalNotes = (e) => {
        e.preventDefault();
        setFormErrors(CreateNoteValidate(CreateNotes));
        setIsSubmit(true);
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            let data = {
                title: CreateNotes.Title,
                description: CreateNotes?.Description,
                theme: image_id,
                created_by: UserData?.userdata?._id
                ,
            };

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${baseApi.baseurl}notes/createnotes`,
                headers: {
                    Authorization: authToken,
                },
                data: data,
            };
            axios
                .request(config)
                .then((response) => {
                    if (response?.data?.message === "Successfully created...") {
                        onClose();
                    } else {
                        toast.error(response?.data?.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error?.data?.message);
                });
        }
        // else{
        //   toast.error("Enter Title and Description");
        // }
    }, [formErrors])

    const handleThemeApplied = () => {
        toast.success('Theme Applied Successfully');
    }


    return (
        // <div className="app-container">
        //     <div className="side-menu-panel">
        //         <div className="menu-panel-logo">
        //             <img className="logo" src={rtylifeLogo} alt="logo" />
        //         </div>
        //     </div>
        //     <div className="content">
        //         <Header
        //             isBack={true}
        //             header={"My Digital Notes"}
        //             isShowIcons={false}
        //         ></Header>
        //         <div
        //             className="dashboard-container"
        //             style={{ maxHeight: "calc(100% - 13%)", padding: "14px" }}
        //         >
        //             <div className="row">
        //                 <div className="col-12">
        //                     <p className="mt-2 mb-2 text-muted">
        //                         Journaling is one of the best way to monitor your feelings. Pour
        //                         them all out with our digital notes.
        //                     </p>
        //                 </div>

        //                 <div className="col-lg-9 col-md-12 mb-3 order-2 order-lg-1">

        //                     <div
        //                         className="dashboard-theme-notes mb-3"
        //                         style={{
        //                             backgroundImage: image
        //                                 ? `url(${image})`
        //                                 : `url(https://images.unsplash.com/photo-1538645731800-4640c639bba7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YnJvd24lMjBiYWNrZ3JvdW5kfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60)`,
        //                         }}
        //                     >
        //                         <div className="row">
        //                             <div className="col-lg-12 containers">
        //                                 <input
        //                                     type="text"
        //                                     className="transparent-input w-100 p-2 px-0"
        //                                     autoComplete="off"
        //                                     maxLength="40"
        //                                     name="Title"
        //                                     value={CreateNotes?.Title}
        //                                     placeholder="Enter Title Here"
        //                                     onChange={handleChange}
        //                                 ></input>
        //                             </div>
        //                         </div>
        //                         <h3 className="secheading py-2">
        //                             {CurrentDate},{CurrentTime}
        //                         </h3>
        //                         <Form className="position-relative">
        //                             <Form.Group
        //                                 className="mb-3"
        //                                 controlId="exampleForm.ControlTextarea1"
        //                             >
        //                                 <div className="mb-3">
        //                                     <textarea
        //                                         className="form-control"
        //                                         name="Description"
        //                                         value={CreateNotes?.Description}
        //                                         rows={18}
        //                                         placeholder="Start typing here..."
        //                                         onChange={handleChange}
        //                                         maxLength="1000"
        //                                     />
        //                                     <span className="text-danger">
        //                                         {formErrors.Title ? formErrors.Title : formErrors.Description}
        //                                     </span>
        //                                 </div>
        //                             </Form.Group>
        //                         </Form>
        //                     </div>

        //                     <div className="col-12 col-md-3 text-end float-end">
        //                         <Button
        //                             type="submit"
        //                             className="button_common notes-btn btn mt-2 btn-primary w-100"
        //                             onClick={(e) => CreateDigitalNotes(e)}
        //                         >
        //                             Save Note
        //                         </Button>
        //                     </div>
        //                 </div>

        //                 <div className="col-lg-3 col-md-12 mb-3 order-1 order-lg-2">

        //                     <div className="accordion select-theme" id="accordionExample">
        //                         <div className="accordion-item">
        //                             <h2 className="accordion-header" id="headingTwo">
        //                                 <button className="accordion-button collapsed secheading px-0 py-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        //                                     Select Note Theme
        //                                 </button>
        //                             </h2>
        //                             <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        //                                 <div className="accordion-body p-0">
        //                                     <div className="dd-menu mt-0">
        //                                         <p className="mt-0 mb-3 text-muted">
        //                                             Choose a theme as per your feelings to pour your heart
        //                                             into{" "}
        //                                         </p>
        //                                         <div className="wrapper-themes mt-3 mb-2 row">
        //                                             {loadingTheam ?
        //                                                 <div className="alert alert-warning" role="alert">
        //                                                     Loading...
        //                                                 </div>
        //                                                 :
        //                                                 Theamlist &&
        //                                                 Theamlist.map((val) => {
        //                                                     return (
        //                                                         <div className="box col-6">
        //                                                             <div className="card-body mb-2">
        //                                                                 <div className="playground-img-div mb-2 ">
        //                                                                     <img
        //                                                                         alt=""
        //                                                                         src={
        //                                                                             `${baseApi.baseurl}` +
        //                                                                             `images/` +
        //                                                                             val?.backgroundimagename
        //                                                                         }
        //                                                                         className="img-fluid select-note-theme-img"
        //                                                                         onClick={() => handleTheamimage(val)}
        //                                                                     />
        //                                                                 </div>
        //                                                                 <div className="support-div-text">
        //                                                                     <h3 className="card-title">
        //                                                                         {val?.themeName}
        //                                                                     </h3>
        //                                                                 </div>
        //                                                             </div>
        //                                                         </div>
        //                                                     );
        //                                                 })}
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>


        //             </div>

        //         </div>
        //     </div>
        // </div>
        <div className="w-[80%] h-full flex flex-grow bg-white rounded-xl shadow-md">
            <div className="w-2/3 h-full flex flex-col border-r border-[#00000040] rounded-l-xl" style={{
                backgroundImage: image
                    ? `url(${image})`
                    : `url(https://images.unsplash.com/photo-1538645731800-4640c639bba7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YnJvd24lMjBiYWNrZ3JvdW5kfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <div className="h-full flex flex-col border-b border-[#00000040]">
                    <input
                        type="text"
                        className="bg-transparent text-[#1C1C1D] text-3xl font-bold placeholder:text-[#1C1C1D] placeholder:text-3xl placeholder:font-bold w-full py-2 px-4 mt-2 outline-none"
                        autoComplete="off"
                        maxLength="40"
                        name="Title"
                        value={CreateNotes?.Title}
                        placeholder="Enter Title Here"
                        onChange={handleChange}
                    ></input>
                    <span className="text-sm text-black mt-2 pl-6 font-semibold">{CurrentDate},{' '}{CurrentTime}</span>
                </div>
                <div className="flex-grow p-2">
                    <textarea
                        name="Description"
                        value={CreateNotes?.Description}
                        rows={20}
                        placeholder="What’s on your mind?"
                        onChange={handleChange}
                        maxLength="1000"
                        className="outline-none pl-4 py-2 w-full text-md font-semibold bg-transparent"
                    />
                    <div className="flex justify-end w-full mt-2 mb-4 pr-4 ">
                        <button
                            type="button"
                            className="bg-white text-black font-semibold py-2 px-4 w-fit outline-none border border-[#F5BD41] shadow-md rounded-xl mr-4"
                            onClick={onClose}
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            className="bg-[#F5BD41] text-black font-semibold py-2 px-4 w-fit border-0 outline-none hover:bg-[#F5BD41] shadow-md rounded-xl"
                            onClick={(e) => CreateDigitalNotes(e)}
                        >
                            Save
                        </button>
                    </div>

                </div>

            </div>
            <div
                className="w-1/3 h-full flex flex-col p-2 rounded-r-xl"
                style={{
                    background: `url(${imagebg})`,
                    backgroundSize: 'auto',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'repeat-y',
                }}
            >
                <div className="w-full h-auto flex-shrink-0">
                    <img src={imagetop} className="w-full h-full object-cover" />
                </div>

                <div className="flex-grow flex flex-col items-center justify-center">
                    <button className="bg-[#F1C7B9] border border-[#E7F0FC] px-3 py-2 font-bold text-sm rounded-xl shadow-md" onClick={() => setOpenThemeModal(true)}>Select a theme</button>
                    <span className="text-black mt-2 text-xs">That suits your mood...</span>
                </div>

                <div className="w-full h-auto flex-shrink-0">
                    <img src={imagebottom} className="w-full h-full object-cover" />
                </div>
            </div>
            <ThemeModal isOpen={openThemeModal} onClose={() => setOpenThemeModal(false)} title={'Gradients'} themeList={Theamlist} onThemeSelect={handleTheamimage} showNotification={handleThemeApplied}/>
        </div>
    );
};

export default CreateNotes;
