import React from 'react'
import { FaStar } from "react-icons/fa";
import defaultUser from "../../Assets/Images/defaultUserImage.jpeg";


const DigitalNotesList = ({ data, image, onClick }) => {
    console.log(data);
    const timestamp = data.createdAt;
    const dateObj = new Date(timestamp);
    const date = dateObj.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
    const time = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
        <div className={`flex flex-row items-center justify-between w-full max-w-2xl rounded-lg overflow-hidden bg-white ${data.response ? 'shadow-custom-reviewed ' : 'shadow-custom-notreviewed '} border-2 mb-4`} onClick={onClick}>
            <div className="flex items-center p-3 w-full h-full">
                <div className="w-16 h-16 rounded-full overflow-hidden">
                    <img
                        src={image ? image : defaultUser}
                        alt="Profile"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="flex flex-col ml-4 flex-grow">
                    <div className="flex justify-between items-center w-full">
                        <h3 className="text-lg font-bold text-gray-900 truncate">{data.title}</h3>
                        <span className="text-sm text-gray-600 font-semibold">{date}</span>
                    </div>
                    <div className="flex justify-between items-center w-full pr-4">
                        <div className='flex flex-col'>
                            <p className="text-gray-700 text-sm mt-1 truncate">{data.description.length > 40 ? data.description.slice(0, 40) + '...' : data.description}</p>
                            <span className="text-sm text-gray-600 mt-2 font-semibold">{time}</span>
                        </div>
                        {data.response && <FaStar size={35} color='#7EBA28' />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalNotesList;