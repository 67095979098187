import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Button from "react-bootstrap/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import commoan from "../../environment/Config";

const Group = () => {
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [InfoList, setInfoList] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const settings = {
    infinite: false,
    slidesToShow: 5,
    lazyLoad: true,
    dots: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrow: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
          infinite: true,
          arrow: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrow: true,
          dots: false,
        },
      },
    ],
    afterChange: function (index) {
      console.log(`Slider Changed to: ${index + 1}`);
    },
  };

  const getInfoList = () => {
    setLoading(true);
    try {
      API.Auth.InfoInfoListAPI({
        helpus: "helpus",
      }).then((response) => {
        if (response?.data?.status === 1) {
          setInfoList(response?.data?.data_all);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfoList();
  }, []);

  const handleStep = () => {
    try {
      API.Auth.UpdateProfileAPI({
        data: {
          step: 4,
        },
        UserId: UserData?.userdata?._id,
      }).then((response) => {
        console.log(response);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //jsx
  return (
    <>

      {/* <div className="container-fluid dashboard m-auto height100vh">
        <div className="row d-flex justify-content-center text-center inner my-0 group-mobile-bg-height height100vh overflow-auto">
          <div className="group-wrap">
            <div className="logo-div mt-5">
              <NavLink to="/">
                <img src="/images/logo.png" className="logo" alt="logo" />
              </NavLink>
            </div>
            <p className="text-center mt-3 mb-4 font-bold">
              Help us serve you better by selecting a Theme that suits you best.
            </p>
            <div className="col-md-12">
              {loading && <div className="container_loader"></div>}
              <Slider {...settings}>
                {InfoList?.length &&
                  InfoList?.map((val) => {
                    return (
                      <div
                        className="inner-div bg-transparent h-100"
                        key={val?.id}
                      >
                        <img
                          className="d-block w-100"
                          src={
                            `${commoan.baseUrl}` +
                            `images/` +
                            val?.backgroundimagename
                          }
                          alt={val?.caption}
                        />
                        <div className="card-body bg-transparent">
                          <h3 className="card-title group-card-title">
                            {val.header}
                          </h3>
                          <p className="card-text">{val?.description}</p>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
              <Button
                type="submit"
                className="button_common"
                onClick={() => [navigate(AdminRoute?.Common?.Plan), handleStep()]}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div> */}
      <div className="w-full bg-gradient-to-b from-[#DDE4ED] to-[#FAE2DF] min-h-screen flex items-center justify-center py-[1%] px-[2%] scrollbar-hide">
        <div className="bg-white bg-opacity-75 rounded-[50px] shadow-lg w-full flex py-[3%]">
          <div className="flex flex-col justify-center item-center w-full">
            <div className="flex justify-center item-center w-full">
              <img src="/images/logo.png" className="h-auto w-60" alt="logo" />
            </div>
            <div className="grid gap-8 grid-cols-4 px-4 mt-4">
              {loading && <div className="container_loader"></div>}
              {/* <Slider {...settings}> */}
              {InfoList?.length &&
                InfoList?.map((val) => {
                  return (
                    <div
                      className="overflow-hidden shadow-lg rounded-xl max-w-[300px]"
                      key={val?.id}
                    >
                      <img
                        className="w-full"
                        src={
                          `${commoan.baseUrl}` +
                          `images/` +
                          val?.backgroundimagename
                        }
                        alt={val?.caption}
                      />
                      <div class="px-6 py-4">
                        <div class="font-bold text-xl mb-2">{val.header}</div>
                        <p class="text-gray-700 text-base">
                          {val?.description}
                        </p>
                      </div>

                    </div>
                  );
                })}
              {/* </Slider> */}


            </div>
            <div className="flex justify-center item-center mt-4">
              <button
                type="submit"
                className="w-fit mt-3 mb-3 font-bold py-2 px-4 rounded-[30px] border-0 bg-[#F5BD41] text-black cursor-pointer"
                onClick={() => [navigate(AdminRoute?.Common?.Plan), handleStep()]}
              >
                Next
              </button>
            </div>
          </div>


        </div>
      </div>
    </>
  );
};
export default Group;
