import React, { useState, useEffect,useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../../Api/Api";
import { IoIosMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import AdminRoute from "../../Route/RouteDetails";
import SessionsIcon from "../../Assets/Images/sidebar/MeetingRoom.png"
import Groupsicon from "../../Assets/Images/sidebar/People.png";
import AiIcon from "../../Assets/Images/sidebar/MessageBot.png";
import logoutArrow from "../../Assets/Images/sidebar/Logout.png";
import { FaHome } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { TbPuzzleFilled } from "react-icons/tb";
import defaultUser from "../../Assets/Images/defaultUser.png";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  // const featureListContext = useContext(FeatrureList);
  const [isExpanded, setIsExpanded] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));

  const Logoutfun = () => {
    localStorage.removeItem("EmailId");
    localStorage.removeItem("AuthTokenClient");
    localStorage.removeItem("ClientUserData");
    navigate(`../${AdminRoute?.Auth?.Login}`)
  }

  const isActive = (path) => {
    if (path === '/groups' && location.pathname.startsWith('/ChatBox')) {
      return true; // Consider /ChatBox as active for groups
    }
    return location.pathname === path;
  };


  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };



  // function getFirstName(fullName) {
  //   return fullName.split(' ')[0];
  // }

  return (
    // <div id="sidebar-wrapper" className="sidebar">
    //   <div className="sidebar-heading text-center header-navbar">
    //     <NavLink to="/">
    //       <img src="/images/logo.png" className="logo" alt="logo" />{" "}
    //     </NavLink>
    //   </div>
    //   <div className="list-group">
    //     <aside className="dropdown-menu-list" id="menu">
    //       <li className="dropdown">
    //         <NavLink
    //           className="pt-2"
    //           eventkey="/"
    //           to={AdminRoute?.Common?.Dashboard}
    //         >
    //           <span>
    //             <img
    //               src="https://cdn-icons-png.flaticon.com/512/83/83966.png"
    //               className="d-icon"
    //               alt="logo"
    //             />
    //           </span>
    //           Home
    //         </NavLink>
    //       </li>
    //       {(featureListContext.CurrentPlan?.find(item => item.features === "Notes")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Notes")?.endDate))) ?
    //         <></> :
    //         <li className="dropdown">
    //           <NavLink
    //             className="pt-2"
    //             eventkey="/"
    //             to={AdminRoute?.Common?.DigitalNotes}
    //           >
    //             <span>
    //               <svg
    //                 src="/images/note.png"
    //                 className="d-icon"
    //                 viewBox="0 0 50 50"
    //               >
    //                 <path d="M 14 4 C 8.4886661 4 4 8.4886661 4 14 L 4 36 C 4 41.511334 8.4886661 46 14 46 L 36 46 C 41.511334 46 46 41.511334 46 36 L 46 14 C 46 8.4886661 41.511334 4 36 4 L 14 4 z M 14 6 L 36 6 C 40.430666 6 44 9.5693339 44 14 L 44 16 L 6 16 L 6 14 C 6 9.5693339 9.5693339 6 14 6 z M 6 18 L 44 18 L 44 26 L 6 26 L 6 18 z M 8 20 L 8 22 L 10 22 L 10 20 L 8 20 z M 12 20 L 12 22 L 14 22 L 14 20 L 12 20 z M 16 20 L 16 22 L 18 22 L 18 20 L 16 20 z M 20 20 L 20 22 L 22 22 L 22 20 L 20 20 z M 24 20 L 24 22 L 26 22 L 26 20 L 24 20 z M 28 20 L 28 22 L 30 22 L 30 20 L 28 20 z M 32 20 L 32 22 L 34 22 L 34 20 L 32 20 z M 36 20 L 36 22 L 38 22 L 38 20 L 36 20 z M 40 20 L 40 22 L 42 22 L 42 20 L 40 20 z M 6 28 L 44 28 L 44 35 L 6 35 L 6 28 z M 6.2011719 37 L 43.798828 37 C 43.288508 40.932523 40.075535 44 36 44 L 14 44 C 9.9244647 44 6.7114915 40.932523 6.2011719 37 z"></path>
    //               </svg>
    //             </span>
    //             Digital Notes
    //           </NavLink>
    //         </li>
    //       }

    //       {(featureListContext.CurrentPlan?.find(item => item.features === "Session")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Session")?.endDate))) ?
    //         <></>
    //         :
    //         <li className="dropdown">
    //           <NavLink
    //             className="pt-2"
    //             eventkey="link-2"
    //             to={AdminRoute?.Common?.MySession}
    //           >
    //             <span>
    //               <img src="/images/session.png" className="d-icon" alt="logo" />
    //             </span>
    //             My Sessions
    //           </NavLink>
    //         </li>
    //       }

    //       {(featureListContext.CurrentPlan?.find(item => item.features === "Groups")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Groups")?.endDate))) ?
    //         <></>
    //         :
    //         <li className="dropdown">
    //           <NavLink
    //             className="pt-2"
    //             eventkey="link-3"
    //             to={AdminRoute?.Common?.Groupchat}
    //           >
    //             <span>
    //               <img src="/images/group.png" className="d-icon" alt="logo" />
    //             </span>
    //             Groups
    //           </NavLink>
    //         </li>
    //       }

    //       {(featureListContext.CurrentPlan?.find(item => item.features === "Therapist")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Therapist")?.endDate))) ?
    //         <></>
    //         :
    //         <li className="dropdown">
    //           <NavLink
    //             className="pt-2"
    //             eventkey="link-4"
    //             to={AdminRoute?.Common?.Aitherapist}
    //           >
    //             <span>
    //               <img src="/images/ai.png" className="d-icon" alt="logo" />
    //             </span>
    //             Al Therapist
    //           </NavLink>
    //         </li>
    //       }

    //       {(featureListContext.CurrentPlan?.find(item => item.features === "Playground")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Playground")?.endDate))) ?
    //         <></>
    //         :
    //         <li className="dropdown">
    //           <NavLink
    //             className="pt-2"
    //             eventkey="/playground"
    //             to={AdminRoute?.Common?.Playground}
    //           >
    //             <span>
    //               <img src="/images/play.png" className="d-icon" alt="logo" />
    //             </span>
    //             Playground
    //           </NavLink>
    //         </li>
    //       }

    //       <li className="dropdown">
    //         <button
    //           className="pt-2"
    //           onClick={() => Logoutfun()}
    //         >
    //           <span>
    //             <img src="/images/logout.png" className="d-icon" alt="logo" />
    //           </span>
    //           Logout
    //         </button>
    //       </li>
    //     </aside>
    //   </div>
    // </div>
    <div
      className={`hidden md:block fixed top-28 left-0 bottom-6 pt-2 px-2 rounded-r-[2rem] cursor-pointer bg-white ${isExpanded ? "w-64" : "w-24"} transition-all duration-300 overflow-hidden z-10 shadow-lg`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}

    >
      <div className="flex items-center justify-center pb-4">
        <button
          className={`flex items-center py-1 md:hidden rounded transition-all duration-300 w-full ${isExpanded ? 'justify-end pr-4' : 'justify-center'}`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <IoMdClose className="text-2xl" /> : <IoIosMenu className="text-2xl" />}
        </button>
      </div>
      <div className={`flex items-center p-2 ${isExpanded ? 'justify-start' : 'justify-center'}`} onClick={() => navigate(`../${AdminRoute.Common.Profile}`)}>
        <img src={UserData?.userdata?.image ? UserData?.userdata?.image: defaultUser} alt="User" className="w-16 h-16 rounded-full" />
        {isExpanded && <span className="ml-4 text-sm font-semibold">{UserData?.userdata?.name}</span>}
      </div>
      <div
        className={`flex items-center my-2 mx-2 p-2 cursor-pointer ${isActive('/') ? (isExpanded ? 'bg-[#F8D68B]' : 'bg-[#FAF5DA]') : ''} rounded-md transition-all duration-300 ${isExpanded ? 'justify-start' : 'justify-center'}`}
        onClick={() => navigate('/')}
      >
        <FaHome className={`w-6 h-6  transition-all duration-300`} color="#000" />
        {isExpanded && <span className="ml-4 text-sm">Home</span>}
      </div>
      <div
        className={`flex items-center my-2 mx-2 p-2 cursor-pointer ${isActive('/DigitalNotes') ? (isExpanded ? 'bg-[#F8D68B]' : 'bg-[#FAF5DA]') : ''} rounded-md transition-all duration-300 ${isExpanded ? 'justify-start' : 'justify-center'}`}
        onClick={() => navigate('/DigitalNotes')}
      >
        <GiNotebook className={`w-6 h-6  transition-all duration-300`} color="#000" />
        {isExpanded && <span className="ml-4 text-sm">Digital Notes</span>}
      </div>
      {[
        { path: '/MySession', icon: SessionsIcon, label: 'Sessions' },
        { path: '/Groupchat', icon: Groupsicon, label: 'Groups' },
        { path: '/Aitherapist', icon: AiIcon, label: 'Ai Assistance' },
      ].map(({ path, icon, label }) => (
        <div
          key={path}
          className={`flex items-center my-2 mx-2 p-2 cursor-pointer ${isActive(path) ? (isExpanded ? 'bg-[#F8D68B]' : 'bg-[#FAF5DA]') : ''} rounded-md transition-all duration-300 ${isExpanded ? 'justify-start' : 'justify-center'}`}
          onClick={() => navigate(path)}
        >
          <img src={icon} alt={label} className={`w-6 h-6  transition-all duration-300`} />
          {isExpanded && <span className="ml-4 text-sm">{label}</span>}
        </div>
      ))}
      <div
        className={`flex items-center my-2 mx-2 p-2 cursor-pointer ${isActive('/Playground') ? (isExpanded ? 'bg-[#F8D68B]' : 'bg-[#FAF5DA]') : ''} rounded-md transition-all duration-300 ${isExpanded ? 'justify-start' : 'justify-center'}`}
        onClick={() => navigate('/Playground')}
      >
        <TbPuzzleFilled className={`w-6 h-6  transition-all duration-300`} color="#000" />
        {isExpanded && <span className="ml-4 text-sm">Playground</span>}
      </div>

      <div
        className={`flex items-center my-2 mx-2 p-2 cursor-pointer rounded-md transition-all duration-300 ${isExpanded ? 'justify-start' : 'justify-center'}`}
        onClick={() => Logoutfun()}
      >
        <img src={logoutArrow} alt="Logout" className="w-6 h-6  transition-all duration-300" />
        {isExpanded && <span className="ml-4 text-sm">Logout</span>}
      </div>
    </div>
  );
}

export default Sidebar;
