import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import API from "../../Api/Api";
import baseApi from "../../Api/config";
import { useNavigate } from "react-router-dom";
import { Worker } from '@react-pdf-viewer/core';
import Modal from "../Model";
import VideoPlayer from "./VideoPlayer";
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import SliderComponent from "./SliderComponent";
import PlaygroundBG from "../../Assets/Images/Playground/PlaygroundBG.png";
import imagetop from "../../Assets/Images/Playground/Board.png";
import imagebottom from "../../Assets/Images/Playground/GirlImage.png";


const Playground = () => {
  const [Mental_HealthList, setMental_HealthList] = useState([]);
  const [Videos, setVideos] = useState([]);
  const [Assessment_List, setAssessment_List] = useState([]);
  const [loadingMental_HealthList, setLoadingMental_HealthList] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loadingAssessment_Lists, setLoadingAssessment_List] = useState(false);

  const [isArticleModalOpen, setArticleModalOpen] = useState(false);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  const navigate = useNavigate();
  const [videoUrl, setVideoUrl] = useState('');
  const [videoPoster, setVideoPoster] = useState('');

  const [articleUrl, setArticleUrl] = useState('');

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //get all data
  useEffect(() => {
    getMntal_HealthList();
    get_VideoList();
    get_AssesmentList();
  }, []);

  //api call for Mental Health  list
  const getMntal_HealthList = () => {
    setLoadingMental_HealthList(true);
    try {
      API.Auth.Mental_HealthAPI({
        health: "health",
      }).then((response) => {
        if (response?.data?.status === 1) {
          setMental_HealthList(response?.data?.data_all);
        }
        setLoadingMental_HealthList(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingMental_HealthList(false);
    }
  };

  //api call for videos

  //api call for Mental Health  list
  const get_VideoList = () => {
    setLoadingVideos(true);
    try {
      API.Auth.Videos_API({
        kind: "kind",
      }).then((response) => {
        if (response?.data?.status === 1) {
          setVideos(response?.data?.data_all);
        }
        setLoadingVideos(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingVideos(false);
    }
  };

  //api  call for self assessment

  const get_AssesmentList = () => {
    setLoadingAssessment_List(true);
    try {
      API.Auth.SelfAsses_API({
        selfAsses: "selfAsses",
      }).then((response) => {
        if (response?.data?.status === 1) {
          setAssessment_List(response?.data?.data_all);
        }
        setLoadingAssessment_List(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingAssessment_List(false);
    }
  };


  const get_video = (e, video) => {
    console.log(video);
    const response = {
      file_path: video,
    };
    window.open(response.file_path);
  };

  // const handleVideoPlay = (url, poster) => {
  //   setVideoPoster(poster);
  //   // setVideoUrl('https://ik.imagekit.io/ikmedia/example_video.mp4'); 
  //   setVideoUrl(url);
  //   setVideoModalOpen(true);
  // };


  // const handleCardClick = (val) => {
  //   setArticleUrl(val.url);
  //   setArticleModalOpen(true);
  // };

  const handleCardClick = (data) => {
    console.log('Card clicked: ', data);
    setArticleModalOpen(true);
  };


  const handleVideoPlay = (data) => {
    console.log('Video clicked: ', data);
    setVideoUrl(data.url);
    setVideoPoster(`${baseApi.baseurl}/images/${data?.backgroundimagename}`);
    setVideoModalOpen(true);
  };


  const handleOpenLink = (data) => {
    window.open(data.url, '_blank');
  };



  return (
    // <div className="app-container">
    //   <div className="side-menu-panel">
    //     <div className="menu-panel-logo">
    //       <img className="logo" src={rtylifeLogo} />
    //     </div>
    //     <div>
    //       <SideBarMenu></SideBarMenu>
    //     </div>
    //   </div>
    //   <div className="content">
    //     <Header isBack={true} header={"Playground"}></Header>
    //     <MobMenuBar></MobMenuBar>
    //     <div className="sub-head px-3 d-flex">
    //       <div>
    //         <p className="sub-lbl mt-3 mb-2">
    //           Here are list of your upcoming post and past sessions
    //         </p>
    //       </div>
    //     </div>
    //     <div
    //       className="dashboard-container"
    //       style={{ maxHeight: "calc(100% - 196px)" }}
    //     >
    //       <div className="sub-head mt-1 px-3">
    //         <p className="sub-heading">Mental Health Resource</p>
    //       </div>
    //       <div className="common-list-hor">
    //         {loadingMental_HealthList ? (
    //           <div className="container_loader"></div>
    //         ) : Mental_HealthList.length === 0 ? (
    //           <span>Data not found</span>
    //         ) : (
    //           <div style={{ overflowX: "auto" }}>
    //             {Mental_HealthList?.map((val) => (
    //               <div
    //                 className="group-card"
    //                 key={val._id}
    //                 style={{
    //                   borderRadius: "14px",
    //                   maxWidth: "200px",
    //                   display: "inline-block",
    //                   marginRight: "10px",
    //                 }}
    //                 onClick={() => handleCardClick(val)}
    //               >
    //                 <div style={{ borderRadius: "15px", overflow: "hidden" }}>
    //                   <img
    //                     style={{
    //                       objectFit: "cover",
    //                       maxHeight: "95px",
    //                       width: "143px",
    //                     }}
    //                     src={
    //                       `${baseApi.baseurl}` +
    //                       `images/` +
    //                       val?.backgroundimagename
    //                     }
    //                     alt={
    //                       `${baseApi.baseurl}` +
    //                       `images/` +
    //                       val?.backgroundimagename
    //                     }
    //                   />
    //                 </div>

    //                 <p className="mb-0 mt-2 group-card-lbl">{val?.header}</p>
    //                 <p className="mb-0">Author: {val?.header}</p>
    //               </div>
    //             ))}
    //           </div>
    //         )}
    //       </div>

    //       <div className="sub-head mt-3 px-3">
    //         <p className="sub-heading">Videos</p>
    //       </div>
    //       <div className="common-list-hor">
    //         {loadingVideos ? (
    //           <div className="container_loader"></div>
    //         ) : Videos.length === 0 ? (
    //           <span>Data not found</span>
    //         ) : (
    //           <div style={{ overflowX: "auto" }}>
    //             {Videos?.map((val) => (
    //               <div
    //                 className="group-card"
    //                 key={val._id}
    //                 style={{
    //                   borderRadius: "14px",
    //                   maxWidth: "200px",
    //                   display: "inline-block",
    //                   marginRight: "10px",
    //                 }}
    //                 onClick={() => handleVideoPlay(val.url, `${baseApi.baseurl}` +
    //                   `images/` +
    //                   val?.backgroundimagename)}
    //               >
    //                 <div style={{ borderRadius: "15px", overflow: "hidden" }}>
    //                   <img
    //                     style={{
    //                       objectFit: "cover",
    //                       maxHeight: "95px",
    //                       width: "143px",
    //                     }}
    //                     src={
    //                       `${baseApi.baseurl}` +
    //                       `images/` +
    //                       val?.backgroundimagename
    //                     }
    //                     alt={
    //                       `${baseApi.baseurl}` +
    //                       `images/` +
    //                       val?.backgroundimagename
    //                     }
    //                   />
    //                 </div>

    //                 <p className="mb-0 mt-2 group-card-lbl">Break The Silence</p>
    //                 <p className="mb-0">Author:  Jitesh Naidu</p>
    //               </div>
    //             ))}
    //           </div>
    //         )}
    //       </div>

    //       <div className="sub-head mt-3 px-3">
    //         <p className="sub-heading">Self Assessment Test</p>
    //       </div>
    //       <div className="common-list-hor">
    //         {loadingAssessment_Lists ? (
    //           <div className="container_loader"></div>
    //         ) : Assessment_List.length === 0 ? (
    //           <span>Data not found</span>
    //         ) : (
    //           <div style={{ overflowX: "auto" }}>
    //             {Assessment_List?.map((val) => (
    //               <a href={val.url} target="_blank" style={{ textDecoration: "none", color: "#000" }}>
    //                 <div className="group-card" style={{
    //                   borderRadius: "14px",
    //                   maxWidth: "200px",
    //                   display: "inline-block",
    //                   marginRight: "10px",
    //                 }} >
    //                   <div style={{ borderRadius: "15px", overflow: "hidden" }}>
    //                     <img
    //                       style={{
    //                         objectFit: "cover",
    //                         maxHeight: "95px",
    //                         borderRadius: "20px",
    //                       }}
    //                       src={
    //                         `${baseApi.baseurl}` +
    //                         `images/` +
    //                         val?.backgroundimagename
    //                       }
    //                       alt={
    //                         `${baseApi.baseurl}` +
    //                         `images/` +
    //                         val?.backgroundimagename
    //                       }
    //                     />
    //                   </div>

    //                   <p className="mb-0 mt-2 group-card-lbl">{val?.header}</p>
    //                   <p className="mb-0">{val?.description}</p>
    //                 </div>
    //               </a>
    //             ))}
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //  
    // </div>

    <div className="flex-grow bg-white m-0 w-full p-0">
      <Header />
      <Sidebar />
      <div className="flex-grow flex flex-col bg-white md:flex-col lg:flex-row md:ml-[8%] h-full md:mr-[4%] md:my-8">
        <div
          className="flex flex-col min-h-screen w-full justify-start items-start rounded-xl z-1"
          style={{
            background: `url(${PlaygroundBG})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="w-full h-full flex flex-grow flex-col bg-transparent ">
            <div className="flex justify-center items-center">
              <span className="text-3xl text-[#000000D4] font-bold">PLAYGROUND</span>
            </div>
            <div className="flex ">
              <div className="w-2/3 h-full p-2" >
                <div className="flex flex-col flex-grow max-h-screen w-full overflow-y-auto overflow-x-hidden scrollbar-hide">
                  <div className="p-2 my-2">
                    <SliderComponent name={'BLOGS'} data={Mental_HealthList} buttonBg='#655DE3' cardBg='#EFEEFF' onCardClick={handleCardClick} />
                  </div>
                  <div className="p-2 my-2">
                    <SliderComponent name={'GUIDED MEDITATION'} data={Videos} buttonBg='#DF7A9A' cardBg='#FFE3EC' onCardClick={handleVideoPlay} />
                  </div>
                  <div className="p-2 my-2">
                    <SliderComponent name={'SELF ASSESMENT'} data={Assessment_List} buttonBg='#97D961' cardBg='#EAFFD9' onCardClick={handleOpenLink} />
                  </div>
                </div>
              </div>
              <div
                className="w-1/3 h-full flex flex-col "
              >
                <div className="w-full h-auto flex-shrink-0">
                  <img src={imagetop} className="w-full h-full object-cover" />
                </div>

                <div className="flex-grow ">
                </div>

                <div className="w-full h-auto flex-shrink-0">
                  <img src={imagebottom} className="w-full h-full object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero_area relative z-0">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
          </g>
        </svg>
      </div>
      <Modal
        isOpen={isArticleModalOpen}
        onClose={() => setArticleModalOpen(false)}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div className="w-full h-full flex justify-center items-center">
            <Viewer
              fileUrl="https://cmsfe.s3.ap-south-1.amazonaws.com/testFile.pdf"
              plugins={[defaultLayoutPluginInstance]}
              className="w-full h-full object-contain"
            />
          </div>
        </Worker>
      </Modal>
      <Modal
        isOpen={isVideoModalOpen}
        onClose={() => setVideoModalOpen(false)}
      >
        <div className="flex justify-center items-center">
          <div className="w-[85%] h-auto">
            <VideoPlayer url={videoUrl} poster={videoPoster} className="h-full w-full" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Playground;
