import React, { useState, useEffect } from "react";
import API from "../../Api/Api";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import groupBg from "../../Assets/Images/Groups/GroupsBG.png";
import GroupDetailsPage from "./GroupDetailsPage";
import GroupCards from "./GroupCards";
import { CiSearch } from "react-icons/ci";
import preloader from '../../Assets/Images/Flip-Flop.gif';


const Groups = () => {

  const tabs = [
    { name: 'Focus', value: 'focused' },
    { name: 'Support', value: 'support' },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]?.value);
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [GroupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);

  const GroupListfun = (type, text) => {
    setLoading(true);
    try {
      API.Group.GroupListIN({
        data: {
          type: type,
          serchKey: '',
          inGroup: true,
        },
      }).then((response) => {
        if (response.data.status === 1) {
          console.log(type, response?.data?.data_all);
          setGroupList(response?.data?.data_all);
          setLoading(false);
        }

      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GroupListfun(activeTab);
  }, [activeTab]);

  const filtered = GroupList.filter((group) =>
    group.name.toLowerCase().includes(searchText.toLowerCase()) ||
    group.description.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleGroupClick = (group) => {
    setSelectedGroup(group); 
  };




  return (
    // <div className="app-container">
    //   <MobMenuBar></MobMenuBar>
    //   <div className="side-menu-panel">
    //     <div className="menu-panel-logo">
    //       <img className="logo" src={rtylifeLogo} alt="logo" />
    //     </div>
    //     <div>
    //       <SideBarMenu></SideBarMenu>
    //     </div>
    //   </div>
    //   <div className="content">
    //     <Header></Header>

    //     <div className="group-container">
    //       <div className="groups-tabs">
    //         <div
    //           className="grp-tab-item cursor-pointer"
    //           style={{
    //             background: activeTab === 0 ? "rgb(242,186,63)" : "white",
    //             color: activeTab === 0 ? "white" : "black",
    //           }}
    //           onClick={() => GroupListfun("focused")}
    //         >
    //           Focused
    //         </div>
    //         <div
    //           className="grp-tab-item cursor-pointer"
    //           style={{
    //             background: activeTab === 1 ? "rgb(242,186,63)" : "white",
    //             color: activeTab === 1 ? "white" : "black",
    //           }}
    //           onClick={() => GroupListfun("support")}
    //         >
    //           Support
    //         </div>
    //       </div>
    //       <div className="grp-search-cont">
    //         <input
    //           placeholder="Search Group"
    //           className="grp_search"
    //           onChange={(e) => handleSearchText(e.target.value)}
    //         />
    //       </div>

    //       <div className="group-list-container px-2">
    //         {loading ? (
    //           <div className="container_loader"></div>
    //         ) : (
    //           <>
    //             {!GroupList?.length ? (
    //               <div className="alert alert-warning w-100" role="alert">
    //                 Data not available.
    //               </div>
    //             ) : (
    //               GroupList &&
    //               GroupList?.map((val, i) => {
    //                 return (
    //                   <div
    //                     className="group-list-item"
    //                     key={i}
    //                     onClick={(e) => gotoChat(e, val)}
    //                   >
    //                     <div className="group-icon-container">
    //                       <img
    //                         src={`${baseApi.baseurl}images/${val?.logo}`}
    //                         alt="user logo"
    //                       />
    //                       <div>
    //                         <p className="group-name">{val?.name}</p>
    //                         <p className="grp-msg-lbl">{val?.description}</p>
    //                       </div>
    //                     </div>
    //                     <div className="text-end">
    //                       <p className="grp-msg-lbl">
    //                         {moment(val?.createdAt).format("ll")}
    //                       </p>
    //                       {/* <span className="text-end">
    //                     <label className="common-count mx-2">3</label>
    //                   </span> */}
    //                     </div>
    //                   </div>
    //                 );
    //               })
    //             )}
    //           </>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="flex flex-col bg-white mx-0 w-full h-full min-h-screen" >
      <div className="flex-grow bg-white m-0 w-full p-0" style={{
        background: `url(${groupBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
      }}>
        <Header />
        <Sidebar />
        <div className="flex flex-grow md:ml-[8%] h-full md:mr-[4%] md:mt-8 relative z-1 p-4 rounded-3xl shadow-md min-h-[80vh] mt-4 radialGradient " >
          <div className="w-1/3 h-full bg-white mr-4 rounded-3xl p-2 pt-4">
            <div className="flex flex-col justify-center items-center w-full">
              <span className="text-[#303030] font-bold text-xl mb-2">Groups</span>
              <div className="w-full text-sm font-medium text-center">
                <ul className="flex flex-wrap -mb-px justify-center items-center">
                  {tabs.map((tab) => (
                    <li key={tab.value} className="mx-8">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab(tab.value);
                        }}
                        className={`relative inline-block p-2 rounded-t-lg text-lg outline-none ${activeTab === tab.value
                          ? 'text-black border-b-2 border-[#F5BD41]'
                          : 'text-gray-600'
                          }`}
                      >
                        {tab.name}
                        <span
                          className={`absolute bottom-0 left-0 w-full h-[2px] outline-none ${activeTab === tab.value
                            ? 'bg-[#F5BD41]'
                            : 'bg-transparent hover:bg-[#F5BD41]'
                            }`}
                        />
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                  <div className='flex flex-col justify-start items-start'>
                    <div className='flex justify-center items-center mt-2 pb-2 w-full'>
                      <div class="relative w-full">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                          <CiSearch size={18} />
                        </div>
                        <input type="text" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-full  block w-full ps-10 p-2.5  outline-none" placeholder="Search Group" onChange={(e) => setSearchText(e.target.value)} />
                      </div>
                    </div>
                    <div className='w-full flex flex-col mt-2 max-h-[45vh] min-h-[45vh] overflow-x-hidden overflow-y-auto scrollbar-none'>
                      {loading ? (<div className='w-full flex justify-center items-center'>
                        <div className='h-10 w-10 rounded-full'>
                          <img src={preloader} className='h-full w-full object-cover' />
                        </div>
                      </div>) : (filtered.length > 0 ? (filtered.map((data, index) => (
                        <GroupCards key={index} data={data} onClick={() => handleGroupClick(data)} isSelected={selectedGroup?._id === data._id}/>
                      ))) : (<div className='flex justify-center items-center'>
                        <p className='text-sm text-black font-semibold'> No groups Found</p>
                      </div>))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-2/3 h-full bg-white rounded-3xl">
          {selectedGroup ? (
              <GroupDetailsPage group={selectedGroup} />
            ) : (
              <div className="flex justify-center items-center h-full min-h-[76vh] ">
                <p className="text-sm text-gray-500 font-semibold">Select a group to view details</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="hero_area relative z-0">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
          </g>
        </svg>
      </div>


    </div>
  );
};

export default Groups;
